import React, { useState, useEffect } from 'react';
import { resetPassword } from '../services/TaskiMapApiService';
import { Button, Form, Col, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/App.css';

function ResetPassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  // Extraer el token y el email de la URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get('email') || '');
    setToken(params.get('token') || '');
  }, [location.search]);

  // Validar si la contraseña es válida y si ambas contraseñas coinciden
  useEffect(() => {
    setIsPasswordValid(password.length >= 8);
    setIsPasswordMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  // Manejar el cambio en los inputs de contraseña
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  // Manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    if (!isPasswordMatch) {
      setError(t('ResetPassword.PasswordMismatch'));
      setLoading(false);
      return;
    }

    try {
      await resetPassword({ email, token, password, password_confirmation: confirmPassword });
      setSuccessMessage(t('ResetPassword.SuccessMessage'));
      setPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError(err.response?.data?.message || t('Errors.resetFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vh-100 d-flex flex-column justify-content-between">
      <Header showTitle={true} showRegisterButton={false} />
      
      <div className="reset-password-container">
        <Col md={6} className="mx-auto">
          <Form onSubmit={handleSubmit} className="reset-password-form shadow-lg p-4 rounded">
            <h1 className="h3 mb-3 text-center">{t('ResetPassword.Title')}</h1>

            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form.Group className="mb-3">
              <Form.Label>{t('ResetPassword.Email')}</Form.Label>
              <Form.Control 
                type="email"
                name="email"
                value={email}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('ResetPassword.NewPassword')}</Form.Label>
              <Form.Control 
                type="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder={t('ResetPassword.NewPasswordPlaceholder')}
                required
                isInvalid={password && !isPasswordValid}
              />
              <Form.Control.Feedback type="invalid">
                {t('ResetPassword.InvalidPassword')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('ResetPassword.ConfirmPassword')}</Form.Label>
              <Form.Control 
                type="password"
                name="confirm_password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder={t('ResetPassword.ConfirmPasswordPlaceholder')}
                required
                isInvalid={confirmPassword && !isPasswordMatch}
              />
              <Form.Control.Feedback type="invalid">
                {t('ResetPassword.PasswordMismatch')}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-between mt-4">
              <Button variant="link" onClick={() => navigate('/')}>
                {t('ResetPassword.Cancel')}
              </Button>

              <Button 
                variant="primary" 
                type="submit" 
                disabled={!isPasswordValid || !isPasswordMatch || loading} 
                className="btn-lg"
              >
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('ResetPassword.Submit')}
              </Button>
            </div>
          </Form>
        </Col>
      </div>

      <Footer />
    </div>
  );
}

export default ResetPassword;