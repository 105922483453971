import React, { useRef, useEffect, useState } from 'react';

import { InputGroup, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TfiPencil, TfiSave } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';

const EditableField = ({ label, labelHtml, name, type, icon, value, editable, onEdit, onSave, onChange, placeholder, showEditButton=true }) => {
  
  const { t } = useTranslation();
  
  const [currentValue, setCurrentValue] = useState(value || '');
  const [originalValue, setOriginalValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const blurTimeoutRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setCurrentValue(value || '');
  }, [value]);

  useEffect(() => {
    if (editable && !isEditing) {
      setOriginalValue(value);  // Guardar el valor original cuando se inicia la edición
      setIsEditing(true);       // Establecer que estamos en modo de edición
      inputRef.current?.focus(); // Asegurarse de enfocar el input cuando se hace editable
    } else if (isEditing && !editable) {
      setIsEditing(false);      // Restablecer el estado de edición cuando se desactiva
    }
  }, [editable, value, isEditing]);

  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
    onChange(e);
  };


  useEffect(() => {
    return () => {
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current);
      }
    };
  }, []);


  const handleBlur = () => {
    if (isEditing) {
      blurTimeoutRef.current = setTimeout(() => {
        onChange({ target: { name, value: originalValue } });
        onEdit();
      }, 200);
    }
  };


  const handleSaveClick = (event) => {
    setLoading(true);

    event.preventDefault();
    onSave(name, value, () => {
      setIsEditing(false);
    });

    // Limpiar el input cuando se hace save, me aseguro que quede con el valor nuevo
    blurTimeoutRef.current = setTimeout(() => {
      onChange({ target: { name, value: value } });
      setLoading(false);
    }, 400);

  };

  const renderButton = () => (
    <OverlayTrigger
      key={editable ? 'save' : 'edit'}
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${editable ? 'save' : 'edit'}`}>
          {editable ? `${t('EditableField.Save')} ${label}` : `${t('EditableField.Edit')} ${label}`}
        </Tooltip>
      }
    >
      <Button 
        variant="outline-secondary"
        onClick={editable ? handleSaveClick : () => onEdit(name)}
        className="ms-auto"
        style={{ borderRadius: '15px', padding: '10px' }}
      >
        {editable ? <TfiSave style={{ fontSize: '1.5rem' }} /> : <TfiPencil style={{ fontSize: '1.5rem' }} />}
      </Button>
    </OverlayTrigger>
  );

  return (
    <>
      <InputGroup className="mt-1" style={{ height: '25px' }}>
        {icon && (
          <InputGroup.Text style={{ border: 'none', background: 'none', fontSize: '1.5rem' }}>
            {icon}
          </InputGroup.Text>
        )}

        <Form.Control 
            name={name}
            ref={inputRef}
            className={editable ? (loading ? 'loadingPlaceholder' : 'gradientLeftToRightLightGray') : ''}
            type={type}
            required
            readOnly={!editable}
            style={{ border: 'none', height: '25px', marginLeft: icon ? '0' : '47px', borderRadius: '5px' }}
            value={currentValue}
            onChange={handleInputChange}
            placeholder={placeholder}
            onBlur={handleBlur}
            autoComplete="off"
          />

        { showEditButton && ( renderButton() )}

      </InputGroup>

      <Form.Text style={{ marginLeft: '60px', fontSize: '0.8rem', color: 'lightgray' }}>
        {labelHtml ? <span dangerouslySetInnerHTML={{ __html: labelHtml }} /> : label}
      </Form.Text>
    </>
  );
}

export default EditableField;

