import React, { useState, useRef, useEffect } from 'react';

import { Modal, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MdOutlineAddPhotoAlternate, MdDelete } from "react-icons/md";
import { requestService } from '../services/TaskiMapApiService';
import { useTranslation } from 'react-i18next';
import CustomTextarea from './CustomTextarea';

const ServiceRequestModal = ({ service, show, handleClose, addRequestedService }) => {

    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (show || service!=null) {
            setMessage('');
            setImages([]);
            setFiles([]);
            setError('');
        }
    }, [show, service]);


    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const newImagesWithPreview = newFiles.map(file => ({
            file,
            url: URL.createObjectURL(file),
            isPreview: true,
            loaded: false
        }));
        setFiles([...files, ...newFiles]);
        setImages([...images, ...newImagesWithPreview]);
    };


    const handleDeleteImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };


    const handleImageDisplay = () => {
        return images.map((img, index) => (
            <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px', marginBottom: '10px' }}>
                <img
                    src={img.url}
                    alt={`Preview ${index}`}
                    style={{ width: '100px', height: '100px' }}
                    onLoad={() => {
                        const newImages = [...images];
                        newImages[index].loaded = true;
                        setImages(newImages);
                    }}
                />
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-delete-${index}`}>{t('Remove')}</Tooltip>}>
                    <Button
                        variant="danger"
                        size="sm"
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={() => handleDeleteImage(index)}
                    >
                        <MdDelete />
                    </Button>
                </OverlayTrigger>
            </div>
        ));
    };


    const handleChange = (e) => {
        setMessage(e.target.value);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError('');

        const formData = new FormData();
        formData.append('service_id', service.id);
        formData.append('message', message);
        files.forEach(file => {
            formData.append('files[]', file);
        });

        try {
            const response = await requestService(formData);
            if (response.data.status) {
                addRequestedService(service.id);
                handleClose();
            } else {
                setError(response.data.message || t('RequestServiceModal.ErrorRequestingService'));
            }
        } catch (error) {
            setError(t('RequestServiceModal.ErrorRequestingService') + ': ' + (error.response?.data?.message || error.message));
        } finally {
            setIsSubmitting(false);
        }
    };


    // Ensure service is defined before rendering the modal
    if (!service) {
        return null;
    }


    return (
        <Modal show={show || service!=null } onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title><h5>{t('RequestServiceModal.Title', { serviceName: service.name })}</h5></Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>

                    {error && <div className="alert alert-danger">{error}</div>}
                    
                    <CustomTextarea
                        label={t('RequestServiceModal.Message')}
                        value={message}
                        onChange={handleChange}
                        editable={true}
                        placeholder={t('RequestServiceModal.MessagePlaceholder')}
                        rows={3}
                    />
                    <div className="image-upload-section" style={{ minHeight: '100px' }}>
                        {images.length > 0 && (
                            <>
                                {handleImageDisplay()}
                                
                                <Button
                                    variant="outline-primary"
                                    style={{ margin: '10px' }}
                                    onClick={() => fileInputRef.current.click()}
                                >
                                    <MdOutlineAddPhotoAlternate /> {t('RequestServiceModal.AddMorePhotos')}
                                </Button>
                            </>
                        )}
                        {images.length === 0 && (
                            <Button
                                variant="outline-primary"
                                style={{ width: '100%' }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                <MdOutlineAddPhotoAlternate /> {t('RequestServiceModal.AddPhotos')}
                            </Button>
                        )}
                        <Form.Control
                            type="file"
                            ref={fileInputRef}
                            multiple
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('RequestServiceModal.Cancel')}
                    </Button>
                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                        {isSubmitting ? t('RequestServiceModal.Requesting') : t('RequestServiceModal.Request')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ServiceRequestModal;

