import React from 'react';

import LoadingScreen from '../components/LoadingScreen';
import Sidebar from '../components/Sidebar';
import useAuth from '../hooks/useAuth';
import '../styles/App.css';

function Invoices() {

  const { isLoading } = useAuth();

  return (
    <div className="d-flex">
      <Sidebar />
      
      <div className="m-3 flex-grow-1">
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            
            <div>Invoices Content Here</div>

          </>
        )}
      </div>
    </div>
  );
}

export default Invoices;


