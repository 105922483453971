import React from 'react';

import { useTranslation } from 'react-i18next';
import StarRating from './StarRating';

const RatingDisplay = ({ ratingValue, ratingCount }) => {

  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-center" style={{maxWidth: '150px'}} >
      <h2 className="mb-0" style={{ fontFamily: "'Roboto', sans-serif", fontWeight: 'bold', color: '#333' }}>
        {ratingValue.toFixed(1)}
      </h2>
      <div className="ms-1">
        <StarRating value={ratingValue} />
        <div className="text-muted" style={{ fontSize: '0.8rem' }}>
          ({ratingCount} {ratingCount === 1 ? t('RatingDisplay.Review') : t('RatingDisplay.Reviews')})
        </div>
      </div>
    </div>
  );
};

export default RatingDisplay;