import React, { useState, useEffect } from 'react';

import { fetchServiceRequest, updateServiceRequest, createRatings } from '../services/TaskiMapApiService';
import { ListGroup, Modal, Image, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomConfirmation from '../components/CustomConfirmation';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import LoadingScreen from '../components/LoadingScreen';
import DisplayRating from '../components/DisplayRating';
import ModalMessage from '../components/ModalMessage';
import RatingModal from '../components/RatingModal';
import { useUser } from '../contexts/UserContext';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';
import { es, enUS } from 'date-fns/locale';
import '../styles/App.css';

function ServiceRequests() {

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];
  const location = useLocation();
  
  const [showConfirmationRate, setShowConfirmationRate] = useState(false);
  const [showApiMessageModal, setShowApiMessageModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [userValidated, setUserValidated] = useState(false);
  const [apiMessageType, setApiMessageType] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');
  const [pendingStatus, setPendingStatus] = useState(null);
  const [pendingRating, setPendingRating] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiMessage, setApiMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { user, validateSession } = useUser();
  const userType = user?.data_user?.user_type;


  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const requestId = parseInt(query.get('id'), 10);
    
    if (requestId && serviceRequests.length) {
      const request = serviceRequests.find(req => req.id === requestId);
      if (request) {
        setSelectedRequest(request);
        setShowModal(true);
      }
    }
  }, [location, serviceRequests]);


  const getServiceRequests = async () => {
    setLoading(true);
    try {
      const response = await fetchServiceRequest();
      setServiceRequests(response.data.data);
      setLoading(false);

    } catch (error) {
      setApiMessage(error.response?.data?.message || 'Failed to fetch service requests.');
      setApiMessageType('error');
      setShowApiMessageModal(true);
      setLoading(false);
    }
  };


  // Map languages to date-fns locales and format time ago
  const localeMap = {
    es: es,
    en: enUS,
    pt: 'pt-BR',
  };


  // Format time ago using date-fns and locale map defined above 
  const formatTimeAgo = (dateString, lng) => {
    const date = parseISO(dateString);
    const locale = localeMap[lng];
    return formatDistanceToNow(date, { addSuffix: true, locale: locale });
  };


  const handleOpenModal = (request) => {
    setSelectedRequest(request);
    setRejectedReason('');
    setShowModal(true);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleUpdateStatus = (newStatus) => {
    setPendingStatus(newStatus);
    setShowConfirmation(true);
  };


  const confirmStatusChange = async () => {
    setIsUpdating(true);

    const requestBody = {
      status: pendingStatus,
      rejected_reason: pendingStatus === 'rejected' ? rejectedReason : ''
    };

    try {
      await updateServiceRequest(selectedRequest.id, requestBody);

      // Actualiza el estado de 'selectedRequest'
      const updatedRequest = { ...selectedRequest, status: pendingStatus };
      setSelectedRequest(updatedRequest);

      // Actualiza la lista de 'serviceRequests' con el estado actualizado
      setServiceRequests(serviceRequests.map(request => {
        return request.id === selectedRequest.id ? updatedRequest : request;
      }));

      setShowConfirmation(false);
      setApiMessage('Status updated successfully.');
      setApiMessageType('info');
    } catch (error) {
      setApiMessage(error.response?.data?.message || 'Failed to update status.');
      setApiMessageType('error');
    } finally {
      setIsUpdating(false);
    }

    setShowApiMessageModal(true);
    setPendingStatus(null);
  };


  // const handleRejectRequest = (request) => {
  //   setPendingStatus('rejected');
  //   setSelectedRequest(request);
  //   setShowConfirmation(true);
  // };


  const handleOpenRatingModal = () => {
    setShowRatingModal(true);
  }


  const confirmRate = (ratingData) => {
    setShowConfirmationRate(true);
    setPendingRating(() => () => handleRate(ratingData));
  }


  const handleRate = async (ratingData) => {
    setIsUpdating(true);

    let data = {
      service_request_id: selectedRequest.id,
      rating: ratingData.rating,
      review: ratingData.review
    };

    await createRatings(data)
    .then(response => {
      // Actualizar el estado local de la solicitud seleccionada con la nueva calificación
      const updatedRatings = [...selectedRequest.ratings, response.data.data];
      const updatedSelectedRequest = { ...selectedRequest, ratings: updatedRatings };
      setSelectedRequest(updatedSelectedRequest);

      // Actualizar globalmente todas las solicitudes para reflejar el cambio
      setServiceRequests(prevRequests => prevRequests.map(req => {
        if (req.id === selectedRequest.id) {
          return { ...req, ratings: updatedRatings };
        }
        return req;
      }));

      setApiMessage('Rating submitted successfully.');
      setApiMessageType('info');
      setShowApiMessageModal(true);
    })
    .catch(error => {
      setApiMessage(error.response?.data?.message || 'Failed to update status.');
      setApiMessageType('error');
      setShowApiMessageModal(true);
    })
    .finally(() => {
      setIsUpdating(false);
    })

    setShowRatingModal(false);
  };


  const handleCloseRatingModal = () => {
    setShowRatingModal(false);
  };


  // Fetch service requests on user validation
  useEffect(() => {
    if (userValidated) {
      getServiceRequests();
    }
  }, [userValidated]);


  // Validate user session on component mount
  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, [validateSession]);


  const renderStatusButtons = (selectedRequest) => {
    const { status, ratings } = selectedRequest;
    const userHasRated = ratings.some(rating => rating.rated_by_user_id === user.id);

    const transitions = {
      'requested': ['rejected', 'accepted'],
      'accepted': ['rejected', 'completed'],
      'rejected': ['accepted'],
      'completed': []
    };

    const classNameVariant = {
      'rejected': 'danger',
      'accepted': 'primary',
      'completed': 'success'
    };

    const lastStatus = {
      'accepted': 'accept',
      'rejected': 'reject',
      'completed': 'complete'
    }

    const statusButtons = userType === 'professional' ? transitions[status]?.map(nextStatus => (
      <Button
        key={nextStatus}
        variant={classNameVariant[nextStatus] || 'secondary'}
        className="m-1"
        onClick={() => handleUpdateStatus(nextStatus)}
        disabled={isUpdating}
      >
        {isUpdating ? t('ServiceRequests.Processing') : t('ServiceRequests.' + lastStatus[nextStatus])}
      </Button>
    )) : [];

    if ((status === 'rejected' || status === 'completed') && !userHasRated) {
      statusButtons.push(
        <Button
          key="rate"
          variant="info"
          className="m-1"
          onClick={() => handleOpenRatingModal(userType, selectedRequest.id)}
          disabled={isUpdating}
        >
          { (userType === 'professional') ? t('ServiceRequests.RateClient') : t('ServiceRequests.RateService') }
        </Button>
      );
    }

    return statusButtons;
  };


  const getStatusStyles = (status) => {
    const styles = {
      requested: { className: 'bg-warning', text: 'Requested' },
      accepted: { className: 'bg-primary', text: 'Accepted' },
      rejected: { className: 'bg-danger', text: 'Rejected' },
      completed: { className: 'bg-success', text: 'Completed' }
    };

    // Retorna el objeto con el estilo y texto dependiendo del estado
    return styles[status] || { className: 'bg-secondary', text: 'Unknown' };  // 'bg-secondary' es un fallback si el estado no está definido
  };


  if (loading) {
    return <LoadingScreen />;
  }

  
  return (
    <div className="d-flex">

      <Sidebar />
    
      <div className="flex-grow-1 p-3">
        <h1>{t('ServiceRequests.ServiceRequests')}</h1>
        
        <ListGroup>
          {serviceRequests.map(request => (

            <ListGroup.Item key={request.id} className="d-flex justify-content-between align-items-start">
              <div className="d-flex align-items-start">
                <img src={request.user.data_user.img_avatar || 'https://via.placeholder.com/150'} alt="Avatar" className="rounded-circle me-3" style={{ width: '50px', height: '50px', border: 'solid 0.5px #C0C0C0' }} />
                <div className="flex-grow-1">
                  <strong>{request.user.name}</strong>
                  
                  <div>{request.message}</div>
                  
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {format(parseISO(request.created_at), 'MMM dd, yyyy, h:mm a')}
                        </Tooltip>
                      }
                    >
                      <div> 
                        <span className="fw-bold">{t('ServiceRequests.RequestedAt')}: </span>
                        <span>{formatTimeAgo(request.created_at, lng)}</span>
                      </div>
                    </OverlayTrigger>
                  </div>

                  <div>
                    <span className="fw-bold">{t('ServiceRequests.Status')}: </span>
                      <span className={`badge ${getStatusStyles(request.status).className}`}>
                        {t('ServiceRequests.' + request.status)}
                      </span>
                  </div>

                  {request.status === 'rejected' && (
                    <div>
                      <p className="text-danger"><strong>{t('ServiceRequests.RejectedReason')}:</strong> {request.rejected_reason}</p>
                    </div>
                  )}

                  {/* Condición para mostrar información adicional del proveedor del servicio si el userType es 'client' */}
                  {userType === 'client' && (
                    <>
                      <hr />
                      <label className="fw-bold h5 bg-dark text-white px-2"> {t('ServiceRequests.YouHaveRequestedTheFollowingService')} </label>
                      <div className="service-provider-details">
                        <strong>{t('ServiceRequests.OfferedBy')}:</strong> {request.service.user.name}
                        <p><strong>{t('ServiceRequests.ServiceDescription')}:</strong> {request.service.description}</p>
                      </div>
                    </>
                  )}

                </div>
              </div>
              <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="mt-auto pt-2">
                  <Button variant="primary" onClick={() => handleOpenModal(request)} className="w-100">{t('ServiceRequests.ViewDetails')}</Button>
                </div>
              </div>
            </ListGroup.Item>

          ))}
        </ListGroup>

        <ModalMessage
          show={showApiMessageModal}
          onHide={() => setShowApiMessageModal(false)}
          title={apiMessageType === 'error' ? 'Error' : 'Information'}
          message={apiMessage}
          type={apiMessageType}
        />

        {selectedRequest && (
          <>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>{t('ServiceRequests.RequestDetails', { name: selectedRequest.user.name })}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ListGroup>
                  <ListGroup.Item><strong>{t('ServiceRequests.RequestedAt')}: </strong>{format(parseISO(selectedRequest.created_at), 'MMM dd, yyyy, h:mm a')}</ListGroup.Item>
                  <ListGroup.Item><strong>{t('ServiceRequests.Message')}: </strong>{selectedRequest.message}</ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-flex justify-content-between">
                      <div className="float-start">
                        <strong>{t('ServiceRequests.Status')}: </strong>{t('ServiceRequests.' + selectedRequest.status)}
                        {selectedRequest.status === 'rejected' && (
                          <div>
                            <p className="text-danger"><strong>{t('ServiceRequests.RejectedReason')}:</strong> {selectedRequest.rejected_reason}</p>
                          </div>
                        )}
                      </div>

                      <div className="float-end">{renderStatusButtons(selectedRequest)}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <div className="image-gallery mt-4">
                  {selectedRequest.files.map((image, index) => (
                    <Image key={index} src={image.url} alt={`Request Image ${index}`} className="img-fluid rounded m-1" style={{ width: '33%', height: 'auto' }} />
                  ))}
                </div>

                {selectedRequest.ratings && selectedRequest.ratings.length > 0 && (
                  <>
                    <hr />
                    <DisplayRating ratings={selectedRequest.ratings} />
                  </>
                )}

              </Modal.Body>
            </Modal>

            <CustomConfirmation
              show={showConfirmationRate}
              onHide={() => setShowConfirmationRate(false)}
              onConfirm={() => pendingRating()}
              title={t('ServiceRequests.ConfirmAction')}
              message={t('ServiceRequests.ConfirmRateCantEditAfterCalificate')}
              variant="warning"
              
            />
          </>
        )}

        {selectedRequest && (
          <CustomConfirmation
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            onConfirm={confirmStatusChange}  // Asegúrate de que esta función maneje un argumento para el motivo del rechazo
            title={t('ServiceRequests.ConfirmAction')}
            message={t('ServiceRequests.ConfirmChangeStatus', { status: t('ServiceRequests.' + pendingStatus) })}
            inputLabel={t('ServiceRequests.ReasonForRejection')}
            placeholder={t('ServiceRequests.EnterRejectionReason')}
            variant="danger"
            inputValue={rejectedReason}
            setInputValue={setRejectedReason}
            showTextArea={pendingStatus === 'rejected'}
          />
        )}

        <RatingModal
          show={showRatingModal}
          onHide={handleCloseRatingModal}
          onRate={confirmRate}
        />

      </div>
    </div>
  );
}

export default ServiceRequests;

