import React, { useRef } from 'react';
import { InputGroup, Form } from 'react-bootstrap';

const CustomTextarea = ({ label, labelHtml, name, editable, placeholder, rows = 3, value, onChange }) => {
  const inputRef = useRef(null);

  return (
    <>
      <InputGroup className="mt-1">
        <Form.Control
          as="textarea"
          name={name}
          ref={inputRef}
          rows={rows}
          required
          readOnly={!editable}
          placeholder={placeholder}
          value={value !== undefined ? value : ''}
          style={{ border: 'none', borderRadius: '5px' }}
          className="custom-textarea"
          onChange={onChange}
        />
      </InputGroup>
      <Form.Text style={{ marginLeft: '11px', fontSize: '0.8rem', color: 'lightgray', top: '-5px', position: 'relative' }}>
        {labelHtml ? <span dangerouslySetInnerHTML={{ __html: labelHtml }} /> : label}
      </Form.Text>
    </>
  );
};

export default CustomTextarea;