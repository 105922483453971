import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import RegisterServiceProvider from './screens/RegisterServiceProvider';
import { WebSocketProvider } from './contexts/WebSocketContext';
import TermsAndConditions from './screens/TermsAndConditions';
import PasswordRecovery from './screens/PasswordRecovery';
import AccountSettings from './screens/AccountSettings';
import ServiceRequests from './screens/ServiceRequests';
import Communications from './screens/Communications';
import { UserProvider } from './contexts/UserContext';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ResetPassword from './screens/ResetPassword';
import CookiePolicy from './screens/CookiePolicy';
import RegisterUser from './screens/RegisterUser';
import EditProfile from './screens/EditProfile';
import { useTranslation } from 'react-i18next';
import Dashboard from './screens/Dashboard';
import Messenger from './screens/Messenger';
import Proposals from './screens/Proposals';
import Services from './screens/Services';
import Invoices from './screens/Invoices';
import Reviews from './screens/Reviews';
import Index from './screens/Index';

import SoundProvider from './contexts/SoundContext';

function App() {
  const { i18n } = useTranslation();
  const [baseLang, setBaseLang] = useState('es');
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const checkInitialization = () => {
      if (i18n.isInitialized) {
        setBaseLang(i18n.language.split('-')[0]);
        setIsReady(true);
      } else {
        setTimeout(checkInitialization, 100);
      }
    };

    checkInitialization();

    return () => {};

  }, [i18n]);

  if (!isReady) {
    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
        }}>
            <div style={{
                border: '8px solid rgba(0, 0, 0, 0.1)',
                borderLeftColor: '#007bff',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                animation: 'spin 1s linear infinite'
            }}></div>

            <style>{`
                @keyframes spin {
                    to { transform: rotate(360deg); }
                }
            `}</style>
        </div>
    );
  }

  return (
    <Router>
      <UserProvider>
        <WebSocketProvider>
          <SoundProvider>
            <Routes>
              <Route path="/reset-password" element={<RedirectToLocalized />} />

              <Route path="/" element={<Navigate replace to={`/${baseLang}`} />} />
              <Route path="/:lng" element={<Index />} />
              <Route path="/:lng/register-user" element={<RegisterUser />} />
              <Route path="/:lng/register-service-provider" element={<RegisterServiceProvider />} />
              <Route path="/:lng/dashboard" element={<Dashboard />} />
              <Route path="/:lng/edit-profile" element={<EditProfile />} />
              <Route path="/:lng/services" element={<Services />} />
              <Route path="/:lng/reviews" element={<Reviews />} />
              <Route path="/:lng/communications" element={<Communications />} />
              <Route path="/:lng/service-requests" element={<ServiceRequests />} />
              <Route path="/:lng/proposals" element={<Proposals />} />
              <Route path="/:lng/invoices" element={<Invoices />} />
              <Route path="/:lng/account-settings" element={<AccountSettings />} />
              <Route path="/:lng/messenger" element={<Messenger />} />
              <Route path="/:lng/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/:lng/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/:lng/cookie-policy" element={<CookiePolicy />} />
              <Route path="/:lng/password-recovery" element={<PasswordRecovery />} />
              <Route path="/:lng/reset-password" element={<ResetPassword />} />

              {/* Opcional: Redirección para manejar URLs que no coinciden */}
              <Route path="*" element={<Navigate replace to={`/${baseLang}`} />} />
            </Routes>
          </SoundProvider>
        </WebSocketProvider>
      </UserProvider>
    </Router>
  );
}


function RedirectToLocalized() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const defaultLang = i18n.language.split('-')[0];
    const path = location.pathname;
    const searchParams = location.search;

    // Redirigir a la versión localizada de la ruta
    navigate(`/${defaultLang}${path}${searchParams}`, { replace: true });
  }, [i18n, location, navigate]);

  return null;
}

export default App;
