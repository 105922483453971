import React, { createContext, useContext, useState, useCallback } from 'react';

import { tryLogin, tryLogout, validateUserSession, tryRegisterClient } from '../services/TaskiMapApiService';
const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const login = async (credentials) => {
        try {
            const response = await tryLogin(credentials);
            setUser(response.data.data.user);
            localStorage.setItem('authToken', response.data.data.token);
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
          await tryLogout();
          localStorage.removeItem('authToken');
          setUser(null);
          window.location.reload();
        } catch (error) {
          console.error('Logout failed:', error);
          throw error;
        }
    };

    // const validateSession = async () => {
    //     const token = localStorage.getItem('authToken');
    //     if (token) {
    //         try {
    //             const response = await validateUserSession();
    //             setUser(response.data.data.user);
    //         } catch (error) {
    //             localStorage.removeItem('authToken');
    //         }
    //     }
    // };
    const validateSession = useCallback(async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const response = await validateUserSession();
                setUser(response.data.data.user);
            } catch (error) {
                localStorage.removeItem('authToken');
            }
        }
    }, []);

    const registerClient = async (formData) => {
        try {
            const response = await tryRegisterClient(formData);
            setUser(response.data.data.user);
            localStorage.setItem('authToken', response.data.data.token);
        } catch (error) {
            console.error('Registration failed:', error);
            throw error;
        }
    };

    const value = { user, setUser, login, logout, validateSession, registerClient };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};