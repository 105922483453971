import React, { useState, useEffect } from 'react';

import { fetchUserSystemPreferences, updateUserSystemPreferences } from '../services/TaskiMapApiService';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import LoadingScreen from '../components/LoadingScreen';
import ModalMessage from '../components/ModalMessage';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';
import { CONST } from '../constants/Const';
import '../styles/App.css';

const AccountSettings = () => {

  const { t } = useTranslation();

  const [modalMessageContent, setModalMessageContent] = useState({ title: '', message: '', type: 'info' });
  const [showModalMessage, setShowModalMessage] = useState(false);
  const currencies = ['USD', 'COP', 'BRL', 'MXN', 'EUR'];  
  const [userValidated, setUserValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user, validateSession } = useUser();
  const [settings, setSettings] = useState({
    allowEmailNotifications: true,
    allowSMSNotifications: false,
    enableChatMessages: true,
    enableServiceVisibility: true,
    allowMarketingEmails: true,
    enableTwoFactorAuth: false,
    currency: 'USD'
  });

  
  const loadPreferences = async () => {
    try {
      const response = await fetchUserSystemPreferences();
      const useInfo = response.data.data;

      setSettings({
        allowEmailNotifications: useInfo.system_preferences.allow_email_notifications,
        allowSMSNotifications: useInfo.system_preferences.allow_sms_notifications,
        enableChatMessages: useInfo.system_preferences.enable_chat_messages,
        enableServiceVisibility: useInfo.system_preferences.enable_service_visibility,
        allowMarketingEmails: useInfo.system_preferences.allow_marketing_emails,
        // enableTwoFactorAuth: useInfo.system_preferences.enable_two_factor_auth,
        currency: useInfo.system_preferences.currency || 'USD',
      });
    } catch (error) {
      setModalMessageContent({ title: 'Error', message: error.response?.data?.message || 'Error fetching user useInfo', type: 'error' });
      setShowModalMessage(true);

    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({
      ...settings,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserSystemPreferences({
        allow_email_notifications: settings.allowEmailNotifications,
        allow_sms_notifications: settings.allowSMSNotifications,
        enable_chat_messages: settings.enableChatMessages,
        enable_service_visibility: settings.enableServiceVisibility,
        allow_marketing_emails: settings.allowMarketingEmails,
        // enable_two_factor_auth: settings.enableTwoFactorAuth,
        currency: settings.currency
      });
      
      setShowModalMessage(true);
      setModalMessageContent({ title: 'Success', message: 'Settings updated successfully.', type: 'success' });

    } catch (error) {
      setModalMessageContent({ title: 'Error', message: error.response?.data?.message || 'Error updating user preferences', type: 'error' });
      setShowModalMessage(true);
    }
  };

  useEffect(() => {
    if (userValidated) {
      loadPreferences();
    }
  }, [userValidated]);

  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, [validateSession]);
  
  return (
    <div className="d-flex">
      <Sidebar />
      <Container fluid className="m-3 flex-grow-1 account-settings-container">
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <h2 className="mb-4">{t('AccountSettings.AccountSettings')}</h2>
            <Form onSubmit={handleSubmit} className="settings-form">
              <Row>
                <Col md={6}>
                  <Form.Group controlId="allowEmailNotifications" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label={t('AccountSettings.AllowEmailNotifications')}
                      name="allowEmailNotifications"
                      checked={settings.allowEmailNotifications}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="allowSMSNotifications" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label={t('AccountSettings.AllowSMSNotifications')}
                      name="allowSMSNotifications"
                      checked={settings.allowSMSNotifications}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  {user.data_user.user_type === CONST.PROFESSIONAL && (
                    <Form.Group controlId="enableChatMessages" className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('AccountSettings.EnableChatMessage')}
                        name="enableChatMessages"
                        checked={settings.enableChatMessages}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  
                </Col>
                <Col md={6}>
                  {user.data_user.user_type === CONST.PROFESSIONAL && (
                    <Form.Group controlId="currency" className="mb-3">
                      <Form.Label>{t('AccountSettings.Currency')}</Form.Label>
                      <Form.Control
                        as="select"
                        name="currency"
                        value={settings.currency}
                        onChange={handleChange}
                      >
                        {currencies.map((currency) => (
                          <option key={currency} value={currency}>
                            {currency}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {user.data_user.user_type === CONST.PROFESSIONAL && (
                    <Form.Group controlId="enableServiceVisibility" className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('AccountSettings.EnableServiceVisibility')}
                        name="enableServiceVisibility"
                        checked={settings.enableServiceVisibility}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}

                  <Form.Group controlId="allowMarketingEmails" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label={t('AccountSettings.AllowMarketingEmails')}
                      name="allowMarketingEmails"
                      checked={settings.allowMarketingEmails}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/*<Form.Group controlId="enableTwoFactorAuth" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Enable Two-Factor Authentication"
                      name="enableTwoFactorAuth"
                      checked={settings.enableTwoFactorAuth}
                      onChange={handleChange}
                    />
                  </Form.Group>*/}
                </Col>
                <Col md={6}>

                </Col>
              </Row>
              <Button variant="primary" type="submit" className="mt-3">
                {t('AccountSettings.SaveChanges')}
              </Button>
            </Form>
          </>
        )}
      </Container>


      {/* Modal para mensajes */}
      <ModalMessage
        show={showModalMessage}
        onHide={() => setShowModalMessage(false)}
        title={modalMessageContent.title}
        message={modalMessageContent.message}
        type={modalMessageContent.type}
      />

    </div>
  );
};

export default AccountSettings;
