import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-bootstrap';

const UserProfilePopover = React.forwardRef(({ user }, ref) => {
  const { t } = useTranslation();

  if (!user || !user.data_user) return null;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  return (
    <Popover id={`popover-user-profile-${user.id}`} ref={ref} style={{ position: 'absolute', borderWidth: '5px' }}>
      <Popover.Header>{`${user.data_user.first_name} ${user.data_user.last_name}`}</Popover.Header>
      <Popover.Body>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img 
            src={user.data_user.img_avatar} 
            alt="User Avatar" 
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              border: '1px solid #ccc',
              objectFit: 'cover'
            }}
          />
        </div>
        <div><strong>Location:</strong> {`${user.data_user.city}, ${user.data_user.state}, ${user.data_user.country}`}</div>
        <p>{user.data_user.about_me}</p>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <strong>{t('UserProfilePopover.MemberSince')}:</strong> {formatDate(user.created_at)}
        </div>
      </Popover.Body>
    </Popover>
  );
});

UserProfilePopover.displayName = 'UserProfilePopover';

export default UserProfilePopover;