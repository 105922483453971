import React from 'react';
import '../styles/App.css';

const LoadingContentPlaceholder = ({ itemCount = 10 }) => {
  
  return (
    <main className="pageContenPlacehoolder">
      <div className="pageContenPlacehoolder-content">
        <div className="placeholder-content">
          {Array.from({ length: itemCount }, (_, index) => ( 
            <div key={index} className="placeholder-content_item"></div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default LoadingContentPlaceholder;
