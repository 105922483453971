import React from 'react';

import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import '../styles/App.css';

const LoadingScreen = ({ message }) => {

  const { t } = useTranslation();

  return (
    <div className="loading-screen">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">{t('LoadingScreen.Loading')}</span>
      </Spinner>
      <h1>{t('LoadingScreen.Loading')}</h1>
    </div>
  );
};

export default LoadingScreen;