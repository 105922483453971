import React, { useState, useEffect, useCallback } from 'react';

import LanguageSwitcher from '../components/LanguageSwitcher';
import { useNavigate, useLocation } from 'react-router-dom';
import { doSearch } from '../services/TaskiMapApiService';
import SearchResults from '../components/SearchResults';
import CookieConsent from '../components/CookieConsent';
import ServicesCard from '../components/ServicesCard';
import { useUser } from '../contexts/UserContext'; 
import { Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import queryString from 'query-string';
import Hero from '../components/Hero';
import '../styles/App.css';

function Index() {

  const { user, logout, validateSession } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { search } = location;

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];

  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || '');
  const [showRequiredLoginModal, setShowRequiredLoginModal] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const API_KEY_GEOAPIFY = process.env.REACT_APP_GEOAPIFY_API_KEY;
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [userValidated, setUserValidated] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  // const [longitude] = useState(queryParams.long || '');
  // const [latitude] = useState(queryParams.lat || '');
  const [loading, setLoading] = useState(false);


  /**
   * Fetches search results based on a geographical location and a search keyword.
   *
   * This function performs an asynchronous HTTP GET request to retrieve search results
   * for a specified keyword within a certain geographical area defined by latitude and longitude.
   * It updates the application state with the results, handles navigation to display these results,
   * and manages the loading state throughout the process.
   *
   * @param {number} latitude - The latitude component of the geographical coordinate.
   * @param {number} longitude - The longitude component of the geographical coordinate.
   * @throws Will throw an error if the request fails, logging the error message to the console.
   */
  const fetchSearchResults = useCallback(async (latitude, longitude) => {
    try {
      setLoading(true);

      let params = {
        keyword: searchKeyword,
        lat: latitude,
        long: longitude
      };

      if (user) {
        params.user_id = user.id;
      }

      const response = await doSearch(params);
      setLoading(false);
      setSearchResults(response);
      setShowSearchResults(true);

      let queryStringData = {
        ...queryParams,
        keyword: searchKeyword,
        lat: latitude,
        long: longitude
      };
      navigate({
        pathname: `/${lng}`,
        search: queryString.stringify(queryStringData)
      });
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error fetching data');
      console.error('Error fetching search results:', error);
    }
  }, [searchKeyword, user, lng, navigate, queryParams]);


  /**
   * Initiates a search process based on the user's current geolocation.
   *
   * This function checks if geolocation services are supported by the user's browser.
   * If supported, it attempts to get the user's current geographical position. Upon success,
   * it triggers a search operation with the user's latitude and longitude through the `fetchSearchResults` function.
   * If the location cannot be obtained due to permission denial or any other error, it sets an appropriate error message.
   * If the browser does not support geolocation, an error message is also set to inform the user.
   *
   * This function directly updates the loading state to indicate that a process is underway and manages
   * error handling by updating the state with relevant messages for the user.
   */
  const handleSearch = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // setLatitude(position.coords.latitude);
          // setLongitude(position.coords.longitude);
          fetchSearchResults(position.coords.latitude, position.coords.longitude);
        },
        () => {
          setErrorMessage('Permission denied or error retrieving your location');
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
    }
  };

  /**
   * Handles the 'Enter' key press event within input fields.
   *
   * This function listens for the 'Enter' key event during input interactions. When the 'Enter' key is pressed,
   * it triggers the `handleSearch` function which initiates the search operation. This provides a smoother and more
   * efficient user experience by allowing search operations to be initiated directly from the keyboard without requiring
   * additional clicks.
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  /**
   * Fetches and updates the geographical location name based on latitude and longitude.
   *
   * This function first checks if the location data for the given coordinates already exists in localStorage
   * to avoid unnecessary API calls. If cached data is available and matches the current coordinates, it uses this data.
   * Otherwise, it makes an API call to Geoapify's reverse geocoding service to fetch the location details.
   * Upon successful API response, it parses the location data, updates the current location state, and caches it in localStorage
   * for future use. If the API call fails or no data is found, it sets an error location state.
   *
   * The function uses React's useCallback hook to ensure that it does not redefine unless necessary, optimizing performance.
   * It is dependent on the translation function `t` from `i18next` to handle localization of fallback and error messages.
   */
  // const fetchLocationName = useCallback((latitude, longitude) => {
  //   const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
  //   fetch(url)
  //   .then(response => response.json())
  //   .then(data => {
  //     if (data && data.address) {
  //       const city = data.address.city || data.address.county;
  //       const country = data.address.country;
  //       const geolocation = `${city}, ${country}`;
  //       setLocation(geolocation || t('Header.UknownLocation'));
  //     } else {
  //       setLocation(t('Header.UknownLocation'));
  //     }
  //     setLoadingLocation(false);
  //   })
  //   .catch(() => {
  //     setLocation(t('Header.ErrorgettingLocation'));
  //     setLoadingLocation(false);
  //   });
  // }, [t]);
  const fetchLocationName = useCallback((latitude, longitude) => {
    const storedLocation = JSON.parse(localStorage.getItem('geoData'));
    if (storedLocation && storedLocation.latitude === latitude && storedLocation.longitude === longitude) {
      setCurrentLocation(storedLocation.location);
      setLoadingLocation(false);
      return;
    }

    const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${API_KEY_GEOAPIFY}`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data && data.features && data.features.length > 0) {
        const firstFeature = data.features[0];
        const city = firstFeature.properties.city || firstFeature.properties.county;
        const country = firstFeature.properties.country;
        const geolocation = `${city}, ${country}`;
        setCurrentLocation(geolocation || t('Header.UnknownLocation'));

        localStorage.setItem('geoData', JSON.stringify({ latitude, longitude, location: geolocation }));
        
      } else {
        setCurrentLocation(t('Header.UnknownLocation'));
      }
      setLoadingLocation(false);
    })
    .catch(() => {
      setCurrentLocation(t('Header.ErrorGettingLocation'));
      setLoadingLocation(false);
    });
  }, [t, API_KEY_GEOAPIFY]);


  /**
   * Handles pagination changes for search results.
   *
   * This function is triggered when a user selects a different page from the pagination interface. It performs an API request
   * to fetch search results for the specified page using the current search criteria such as keyword, latitude, and longitude.
   * The function sets a loading state at the beginning and after the API call completes, it updates the application state
   * with the new search results and clears the loading state. If the API request is successful, the URL is updated to reflect
   * the new page, preserving the existing search parameters to maintain the context. In case of an error during the API request,
   * the error is logged to the console, and the loading state is cleared to remove any loading indicators.
   *
   * @param {number} page - The new page number to fetch data for.
   */
  const handlePageChange = async (page, latitude, longitude) => {
    try {
      setLoading(true);

      let params = {
        keyword: searchKeyword,
        lat: latitude,
        long: longitude,
        page: page
      };
      
      const response = await doSearch(params);
      
      setLoading(false); // Finaliza el indicador de carga
      setSearchResults(response); // Actualiza los resultados de búsqueda con la nueva página
      setShowSearchResults(true); // Asegúrate de que los resultados de búsqueda se muestren

      // Actualiza la URL para reflejar la nueva página en la consulta, manteniendo los otros parámetros
      navigate(`/${lng}?${queryString.stringify({ keyword: searchKeyword, lat: latitude, long: longitude, page: page })}`);

    } catch (error) {
      setLoading(false); // Finaliza el indicador de carga en caso de error
      console.error('Error fetching search results:', error);
    }
  };


  /**
   * Effect hook that triggers a search operation based on URL query parameters.
   *
   * This useEffect hook listens for changes in the `keyword`, `lat`, and `long` query parameters from the URL. When any of these parameters change,
   * indicating either a new search request or a navigation event that modifies the URL, the search results are fetched using the updated parameters.
   * It ensures that the search keyword state within the component matches the keyword from the URL and then invokes `fetchSearchResults` to perform
   * the search using the provided latitude and longitude. This synchronization allows for consistent behavior when sharing or bookmarking URLs,
   * enabling users to return to the same search state by navigating directly with a URL.
   *
   * @dependency {queryParams.keyword, queryParams.lat, queryParams.long} - The query parameters that trigger the effect when they change.
   */
  useEffect(() => {
    if (userValidated && queryParams.keyword && queryParams.lat && queryParams.long) {
      setSearchKeyword(queryParams.keyword);
      fetchSearchResults(queryParams.lat, queryParams.long);
    }
  }, [userValidated, queryParams.keyword, queryParams.lat, queryParams.long]);


  /**
   * Logout the current user from the application.
   *
   * This function is responsible for handling the user logout process. It performs several key actions:
   * 1. It makes an HTTP POST request to the '/v1/logout' endpoint to notify the server that the user is logging out. This helps in clean up or session invalidation on the server side.
   * 2. It removes the 'authToken' from localStorage, effectively erasing the client's credentials and preventing further authenticated requests without a new login.
   * 3. It resets the user state by setting it to null, which updates the application's UI to reflect that no user is currently logged in.
   * 4. It navigates the user back to the homepage (or a language-specific base route) using React Router's `navigate` function, ensuring the user is redirected away from potentially authenticated-only areas of the application.
   *
   * If an error occurs during the logout process (e.g., the server could not be reached), it logs the error to the console and sets an error message in the state to inform the user that the logout process failed.
   */
  const handleLogout =  () => {
    logout();
    navigate(`/${lng}${search}`);
  };


  /**
   * Handles the successful acquisition of the user's geolocation.
   *
   * This callback is triggered when the user's geographic position is successfully retrieved via the browser's geolocation API.
   * It extracts the latitude and longitude coordinates from the position object provided by the geolocation API.
   *
   * @param {GeolocationPosition} position - Contains the geographic coordinates of the user.
   * The latitude and longitude are extracted from position.coords, which is passed to the fetchLocationName function.
   * 
   * The fetchLocationName function is then called with these coordinates to reverse geocode the location (convert coordinates to a human-readable address).
   * This function fetches and sets the location data based on the coordinates provided.
   *
   * Dependencies:
   * - fetchLocationName: A useCallback dependency that handles the API request for reverse geocoding. It needs to be included in the dependency array
   *   to ensure that it has the latest version if dependencies within that function change.
   */
  const handleGeolocationSuccess = useCallback((position) => {
    const { latitude, longitude } = position.coords;
    fetchLocationName(latitude, longitude);
  }, [fetchLocationName]);
  

  /**
   * Handles errors encountered while attempting to retrieve the user's geolocation.
   *
   * This function is invoked if there is an error during the geolocation retrieval process. The possible reasons could include
   * permission denial by the user, unavailability of the geolocation service, or other failures.
   *
   * @param {GeolocationPositionError} error - The error object representing what went wrong during the geolocation retrieval.
   *
   * Inside this function:
   * - `setCurrentLocation(false)`: Indicates an unsuccessful geolocation fetch by setting the current location to false.
   * - `setLoadingLocation(false)`: Updates the loading state to false, signaling that the loading process has completed,
   *   regardless of success or failure.
   */
  const handleGeolocationError = useCallback((error) => {
    setCurrentLocation(false);
    setLoadingLocation(false);
  }, []);


  /**
   * Initiates a geolocation request to obtain the user's current geographic position.
   *
   * This function triggers the browser's geolocation API to ask the user for permission to access their location data.
   * If permission is granted, `handleGeolocationSuccess` is called with the position; if denied or an error occurs,
   * `handleGeolocationError` is triggered.
   *
   * Effects:
   * - Sets the loading state for location fetching to true, indicating the start of a geolocation process.
   * - Utilizes the navigator.geolocation API to asynchronously fetch the user's position.
   */
  const requestLocationPermission = () => {
    setLoadingLocation(true);
    navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
  };


  /**
   * Effect hook to manage geolocation fetching and session validation on component mount.
   *
   * On component mount, this useEffect:
   * - Checks if geolocation is supported by the browser.
   * - If supported, it sets the loading state for location data to true and initiates geolocation fetching.
   * - Independently validates the user session by checking if there's a stored authentication token and validating it.
   *
   * Dependencies:
   * - `handleGeolocationSuccess`: A callback function that handles the successful retrieval of geolocation.
   * - `handleGeolocationError`: A callback function that handles any errors during the geolocation retrieval process.
   *
   * This arrangement ensures that both user location and authentication state are verified any time relevant dependencies change,
   * maintaining synchronized state with user permissions and authentication status.
   */
  useEffect(() => {
    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
    }
    validateSession().then(() => setUserValidated(true));
  }, [handleGeolocationSuccess, handleGeolocationError]);


  return (
    <>
      <div className="vh-100 d-flex flex-column justify-content-between">
        <Header 
          showSearchBar={showSearchResults}
          onSearch={handleSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          handleKeyDown={handleKeyDown}
          handleLogout={handleLogout}
          requestLocationPermission={requestLocationPermission}
          loadingLocation={loadingLocation}
          currentLocation={currentLocation}
        />
        
        <main className="px-4 flex-grow-1 d-flex flex-column justify-content-center">

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          !showSearchResults ? (
            <>
              <Hero 
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                handleKeyDown={handleKeyDown}
                loading={loading}
                handleSearch={handleSearch}
              />
              <ServicesCard />
              <LanguageSwitcher />
            </>
          ) : (
            queryParams.keyword && queryParams.lat && queryParams.long ? (
              <SearchResults 
                results={searchResults}
                onPageChange={handlePageChange}
                showRequiredLoginModal={showRequiredLoginModal}
                setShowRequiredLoginModal={setShowRequiredLoginModal}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                latitude={queryParams.lat}
                longitude={queryParams.long}
              />
            ) : null
          )
        )}
        </main>
        
        <Footer />

      </div>


      <CookieConsent />
    </>

  );
}

export default Index;

