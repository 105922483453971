import React, { useEffect, useState, useRef } from 'react';

import { BsCameraFill, BsFillCheckCircleFill, BsUpload, BsCameraVideoFill, BsJournalBookmark } from 'react-icons/bs';
import { Form, Button, Row, Col, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import VerificationStatusBadge from '../components/VerificationStatusBadge';
import BtnPhoneVerification from '../components/BtnPhoneVerification';
import BtnEmailVerification from '../components/BtnEmailVerification';
import { FiUser, FiMail, FiPhone, FiMapPin } from 'react-icons/fi';
import EditableTextArea from '../components/EditableTextArea';
import UpdatePhoneModal from '../components/UpdatePhoneModal';
import UpdateEmailModal from '../components/UpdateEmailModal';
import { BiIdCard, BiCreditCardFront } from 'react-icons/bi';
import EditableSelect from '../components/EditableSelect';
import EditableField from '../components/EditableField';
import ModalMessage from '../components/ModalMessage';
import { PiUserRectangleFill } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { updateUserField,
  fetchUserProfile,
  profileVerificationProcess,
  fetchVerificationStatus,
  fetchCountries,
  fetchStatesByCountry,
  fetchCitiesByState
} from '../services/TaskiMapApiService';
import '../styles/App.css';

function ProfileServiceProvider() {

  const { t } = useTranslation();
  
  const [profileImage, setProfileImage] = useState('https://dummyimage.com/100x100');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [imageProfileIsLoading, setImageProfileIsLoading] = useState(false);
  const [showLargeImageModal, setShowLargeImageModal] = useState(false);
  const [profileVerified, setProfileVerified] = useState(false);
  const [modalMessageContent, setModalMessageContent] = useState({ title: '', message: '', type: 'info' });
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [verificationProcessStarted, setVerificationProcessStarted] = useState(false);
  const [isVerificationButtonDisabled, setIsVerificationButtonDisabled] = useState(false);
  const [showChangeCountryModal, setShowChangeCountryModal] = useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [facePhotoImage, setFacePhotoImage] = useState('');
  const [frontDocImage, setFrontDocImage] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [backDocImage, setBackDocImage] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const facePhotoRef = useRef(null);
  const fileInputRef = useRef(null);
  const frontDocRef = useRef(null);
  const backDocRef = useRef(null);
  const streamRef = useRef(null);

  const [editable, setEditable] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    country: false,
    state: false,
    city: false,
    zip: false,
    about_me: false
  });

  const [userInfo, setUserInfo] = useState({
    fullName: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    about_me: '',
    email_verified_at: '',
    phone_verified_at: '',
    profile_verification_process: '',
  });

  const [tempCountry, setTempCountry] = useState(userInfo.country);


  /**
   * Alterna el estado editable de un campo específico en el formulario del perfil.
   * Esto permite a los usuarios cambiar entre la visualización y la edición de sus datos.
   * @param {string} field - El nombre del campo que se alterará.
   */
  const handleEdit = (field) => {
    setEditable(prev => ({ ...prev, [field]: !prev[field] }));
  };


  /**
   * Actualiza el estado con los valores de los campos de formulario cada vez que se hace un cambio.
   * Esto asegura que el estado siempre refleje el contenido más actual de los campos de entrada.
   * @param {React.ChangeEvent<HTMLInputElement>} e - El evento de cambio del input del formulario.
   */
  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };


  /**
   * Maneja la carga y visualización de una imagen de perfil seleccionada por el usuario.
   * Lee el archivo seleccionado, lo convierte a base64 y lo establece como la imagen de perfil actual.
   * @param {React.ChangeEvent<HTMLInputElement>} e - El evento de cambio cuando se selecciona un archivo.
   */
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar si el archivo es una imagen
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            // Obtiene el resultado de la conversión a base64
            const base64Image = event.target.result;
            // Establecer la imagen de perfil localmente
            setProfileImage(base64Image);
            // Enviar la imagen al servidor
            await handleSave('img_avatar', base64Image);
          } catch (error) {
            // Manejar errores tanto en la conversión como en la subida
            setModalMessageContent({ title: 'Error', message: 'Error processing the image. Please try again.', type: 'error' });
            setShowModalMessage(true);
          }
        };
        reader.readAsDataURL(file);
      } else {
        // Si el archivo no es una imagen, emitir un error
        setModalMessageContent({ title: 'Invalid File', message: 'Please select a valid image file.', type: 'error' });
        setShowModalMessage(true);
      }
    }
    setShowModal(false);
  };


  /**
   * Observa cambios en los nombres del usuario para actualizar el nombre completo.
   * Esto asegura que el nombre completo siempre esté actualizado cuando se cambian el nombre o los apellidos.
   * Es disparado por cambios en `first_name` o `last_name`.
   */
  useEffect(() => {
    setUserInfo(prev => ({
      ...prev,
      fullName: `${prev.first_name} ${prev.last_name}`
    }));
  }, [userInfo.first_name, userInfo.last_name]);


  /**
   * Llama a la API para guardar un campo específico del perfil del usuario.
   * Envía una solicitud POST al servidor con el campo y su nuevo valor.
   * Actualiza el estado local del usuario si la actualización es exitosa.
   * Muestra un mensaje modal en caso de error.
   * @param {string} field - El nombre del campo del perfil a actualizar.
   * @param {any} value - El nuevo valor para el campo especificado.
   */
  const handleSave = async (field, value) => {
    try {
      if (field === 'img_avatar') {
        setImageProfileIsLoading(true);
      }

      // Llamada al endpoint de actualización
      await updateUserField(field, value);

      // Actualizar el estado local con el nuevo valor
      setUserInfo(prev => ({...prev, [field]: value }));

      if (field === 'img_avatar') {
        setImageProfileIsLoading(false);
      }

    } catch (error) {
      setModalMessageContent({ title: 'Error', message: error.response?.data?.message || 'Failed to update', type: 'error' });
      setImageProfileIsLoading(false);
      setShowModalMessage(true);
    }
  };


  /**
   * Maneja el evento onClick para abrir la interfaz de la cámara y capturar una foto.
   * Establece un flujo de medios en el elemento de vídeo y permite al usuario capturar una imagen,
   * que se utilizará potencialmente como imagen de perfil del usuario.
   */
  const openCamera = async () => {
    try {
      // Solicitar acceso a la cámara
      const constraints = { video: true };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;
      
      // Asignar el stream directamente al video
      const videoElement = document.createElement('video');
      videoElement.srcObject = stream;
      videoElement.autoplay = true; // Iniciar la reproducción automáticamente
      
      // Opcional: Mostrar un botón para capturar la imagen
      const captureButton = document.createElement('button');
      captureButton.textContent = t('ProfileServiceProvider.Capture');
      captureButton.onclick = () => captureImage(stream, videoElement);
      
      // Agregar video y botón al cuerpo del modal
      const modalBody = document.querySelector('.modal-body');
      modalBody.innerHTML = ''; // Limpiar contenido anterior
      modalBody.appendChild(videoElement);
      modalBody.appendChild(captureButton);

    } catch (error) {
      setModalMessageContent({ title: 'Error', message: error, type: 'error' });
      setShowModalMessage(true);
    }
  };


  /**
   * Captura una imagen de la transmisión de vídeo activa.
   * Toma un fotograma del vídeo y lo convierte en una imagen estática en un elemento canvas,
   * que luego se convierte en una URL de datos base64 y se establece como imagen de perfil.
   * @param {MediaStream} stream - El flujo de medios actual de la cámara.
   * @param {HTMLVideoElement} videoElement - El elemento de vídeo que muestra la transmisión.
   */
  const captureImage = (stream, videoElement) => {
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    // Detener todos los tracks del stream
    stream.getTracks().forEach(track => track.stop());

    // Convertir la imagen del canvas a una URL de imagen
    const imageURL = canvas.toDataURL('image/png');
    setProfileImage(imageURL); // Actualizar la imagen de perfil
    setShowModal(false); // Cerrar el modal

    // Llamar a la API para guardar la imagen de perfil
    handleSave('img_avatar', imageURL);
  };


  /**
   * Maneja el cierre del modal de la cámara.
   * Detiene la transmisión de la cámara al cerrar el modal para liberar recursos.
   */
  const handleModalClose = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop()); // Detiene la cámara
      streamRef.current = null;
    }
    setShowModal(false); // Cierra el modal
  };


  /**
   * Carga el perfil del usuario desde el servidor cuando el componente se monta.
   * Realiza una solicitud GET para obtener los datos del usuario y los establece en el estado local.
   * En caso de error, muestra un mensaje modal.
   */
  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const response = await fetchUserProfile();
        if (response.data.status && response.data.data && response.data.data.user) {

          const data = response.data.data.user;

          setUserInfo({
            fullName: `${data.data_user.first_name} ${data.data_user.last_name}`,
            first_name: data.data_user.first_name,
            last_name: data.data_user.last_name,
            email: data.email,
            phone: data.data_user.phone,
            country: data.data_user.country,
            state: data.data_user.state,
            city: data.data_user.city,
            zip: data.data_user.zip,
            about_me: data.data_user.about_me,
            email_verified_at: data.email_verified_at,
            phone_verified_at: data.data_user.phone_verified_at,
            profile_verification_process: data.data_user.profile_verification_process,
          });

          // Establecer la verificación de perfil
          setProfileVerified(data.data_user.identity_verified);

          // Cargar la imagen de perfil en base64 si está disponible
          if (data.data_user.img_avatar) {
            setProfileImage(data.data_user.img_avatar);
          }
        }
      } catch (error) {
        setModalMessageContent({ title: 'Error', message: error, type: 'error' });
        setShowModalMessage(true);
      }
    };

    loadUserProfile();
  }, []);


  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setModalMessageContent({ title: 'Invalid File', message: 'Please select a valid image file.', type: 'error' });
      setShowModalMessage(true);
    }
  };


  const handleSubmitVerification = async () => {
    try {
      const payload = {
        identity_document_type: documentType,
        img_identity_document_front: frontDocImage,
        img_identity_document_back: backDocImage,
        img_face_photo: facePhotoImage
      };
      setVerificationProcessStarted(true);
      await profileVerificationProcess(payload);
      
      setShowModalMessage(true);
      setModalMessageContent({ title: 'Success', message: 'Verification started successfully.', type: 'success' });
      setShowVerificationModal(false);
      
      // Restablecer estados
      setFrontDocImage('');
      setBackDocImage('');
      setFacePhotoImage('');
      setDocumentType('');

    } catch (error) {
      setModalMessageContent({ title: 'Error', message: error.response?.data?.message || 'Failed to start verification', type: 'error' });
      setShowModalMessage(true);

    } finally {
      setVerificationProcessStarted(false);

    }
  };


  const getVerificationStatus = async () => {
    try {
      const response = await fetchVerificationStatus();

      if (response.data.status && response.data.data) {
        const data = response.data.data;
        setVerificationStatus(data.profile_verification_process);
        setDocumentType(data.identity_document_type);
        setFrontDocImage(data.img_identity_document_front);
        setBackDocImage(data.img_identity_document_back);
        setFacePhotoImage(data.img_face_photo);

        // Configurar la habilitación del botón según el estado del proceso
        setIsVerificationButtonDisabled(['started', 'on_verification', 'approved'].includes(data.profile_verification_process));
      }
    } catch (error) {
      setShowModalMessage(true);
      setModalMessageContent({ title: 'Error', message: error, type: 'error' });
    }
  };


  // Llamar esta función cuando se abra el modal de verificación
  useEffect(() => {
    if (showVerificationModal) {
      getVerificationStatus();
    }
  }, [showVerificationModal]);


  // Carga inicial de países
  useEffect(() => {
    const getCountries = async () => {
      const response = await fetchCountries();
      if (response.data.status) {
        setCountries(response.data.data);
      }
    };
    getCountries();
  }, []);


  // Cargar estados basados en el país seleccionado
  useEffect(() => {
    const fetchStates = async () => {
      if (userInfo.country) {
        const response = await fetchStatesByCountry(userInfo.country);
        if (response.data.status) {
          setStates(response.data.data);
        }
      }
    };
    if (userInfo.country) {
      fetchStates();
    }
  }, [userInfo.country]);


  // Cargar ciudades basadas en el estado seleccionado
  useEffect(() => {
    const fetchCities = async () => {
      if (userInfo.state) {
        const response = await fetchCitiesByState(userInfo.state);
        if (response.data.status) {
          setCities(response.data.data);
        }
      }
    };
    if (userInfo.state) {
      fetchCities();
    }
  }, [userInfo.state]);


  const handleUpdateEmail = () => {
    setShowUpdateEmailModal(true);
  };


  const handleUpdatePhone = () => {
    setShowUpdatePhoneModal(true);
  };


  const handleCountryChange = (e) => {
    const newCountry = e.target.value;
    if (userInfo.profile_verification_process === 'approved') {
      setTempCountry(newCountry);
      setShowChangeCountryModal(true);
    } else {
      handleChange(e);
    }
  };


  const confirmCountryChange = () => {
    setUserInfo((prev) => ({ ...prev, country: tempCountry }));
    setShowChangeCountryModal(false);
  };


  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <Form>
          <Row className="justify-content-center">
            <Col md={8}>

              {/* Foto y nombre del usuario */}
              <div className="text-center">
                <div className="profile-image-container position-relative d-inline-block">
                  <img src={profileImage} alt="Profile" className="rounded-circle" style={{ width: '100px', height: '100px', objectFit: 'cover', border: 'solid 0.5px #C0C0C0' }} onClick={() => setShowLargeImageModal(true)} />
                  {imageProfileIsLoading && <div className="loading-effect"></div>}
                  <BsCameraFill className="camera-icon position-absolute" onClick={() => setShowModal(true)} style={{ bottom: 0, right: 0, fontSize: '24px', cursor: 'pointer', borderRadius: '30%' }} />
                </div>
                
                <h3 className="mt-2">{userInfo.fullName}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-verified">{t('ProfileServiceProvider.ProfileVerified')}</Tooltip>}
                  >
                    <span> {/* Envoltura adicional para manejar el tooltip de lo contrario la imagen es muy pequeña y no se muestra */}
                      {!!profileVerified && (
                        <BsFillCheckCircleFill className="text-success ms-2" style={{ fontSize: '20px', cursor: 'pointer' }} />
                      )}
                    </span>
                  </OverlayTrigger>
                </h3>

                {!profileVerified && (
                  <Button variant="outline-primary" className="ms-2 btn-sm" onClick={() => setShowVerificationModal(true)}>{t('Generic.RequestProfileVerification')}</Button>
                )}
              </div>

              {/* Información de Contacto */}
              <div>
                <h5 className="section-title d-flex align-items-center">
                  {t('ProfileServiceProvider.ContactInfo')}
                </h5>

                <EditableField 
                  label={t('ProfileServiceProvider.Names')}
                  name="first_name"
                  type="text" 
                  icon={<FiUser />} 
                  value={userInfo.first_name}
                  editable={editable["first_name"]} 
                  onEdit={() => handleEdit("first_name")} 
                  onChange={handleChange} 
                  onSave={handleSave}
                  placeholder={t('ProfileServiceProvider.Names')}
                />

                <EditableField 
                  label={t('ProfileServiceProvider.LastNames')}
                  name="last_name"
                  type="text"
                  value={userInfo.last_name}
                  editable={editable["last_name"]} 
                  onEdit={() => handleEdit("last_name")} 
                  onChange={handleChange} 
                  onSave={handleSave}
                  placeholder={t('ProfileServiceProvider.LastNames')}
                />

                <div>
                  <EditableField 
                    label={userInfo.email_verified_at ? null : t('ProfileServiceProvider.Email')}
                    labelHtml={userInfo.email_verified_at ? t('ProfileServiceProvider.EmailVerifiedHtml') : null}
                    name="email"
                    type="text"
                    icon={<FiMail />} 
                    value={userInfo.email}
                    editable={editable["email"]} 
                    onEdit={!userInfo.email_verified_at ? () => handleEdit("email") : handleUpdateEmail}
                    onChange={handleChange} 
                    onSave={handleSave}
                    placeholder={t('ProfileServiceProvider.Email')}
                  />
                </div>

                {/* Email Verification Button */}
                <BtnEmailVerification
                  isVerified={!!userInfo.email_verified_at}
                  email={userInfo.email}
                  onVerificationSuccess={() => setUserInfo(prev => ({ ...prev, email_verified_at: new Date().toISOString() }))}
                />

                <div>
                  <EditableField 
                    label={userInfo.phone_verified_at ? null : t('ProfileServiceProvider.Phone')}
                    labelHtml={userInfo.phone_verified_at ? t('ProfileServiceProvider.PhoneVerifiedHtml') : null}
                    name="phone"
                    type="text"
                    icon={<FiPhone />} 
                    value={userInfo.phone}
                    editable={editable["phone"]} 
                    onEdit={!userInfo.email_verified_at ? () => handleEdit("phone") : handleUpdatePhone}
                    onChange={handleChange} 
                    onSave={handleSave}
                    placeholder={t('ProfileServiceProvider.Phone')}
                  />
                </div>

                {/* Phone Verification Button */}
                <BtnPhoneVerification
                  isVerified={!!userInfo.phone_verified_at}
                  phone={userInfo.phone}
                  onVerificationSuccess={() => setUserInfo(prev => ({ ...prev, phone_verified_at: new Date().toISOString() }))}
                />
              </div>

              {/* Direccion */}
              <div className="mt-2">
                <h5 className="section-title d-flex align-items-center">
                  {t('ProfileServiceProvider.Address')}
                </h5>

                <EditableSelect
                  icon={<FiMapPin />} 
                  label={t('ProfileServiceProvider.Country')}
                  name="country"
                  value={userInfo.country}
                  editable={editable["country"]}
                  onEdit={() => handleEdit("country")}
                  onSave={handleSave}
                  // onChange={handleChange}
                  onChange={handleCountryChange}
                  options={countries.map(country => ({ value: country.name, label: country.display_name }))}
                  placeholder={t('ProfileServiceProvider.Country')}
                />

                <EditableSelect
                  label={t('ProfileServiceProvider.State')}
                  name="state"
                  value={userInfo.state}
                  editable={editable["state"]}
                  onEdit={() => handleEdit("state")}
                  onSave={handleSave}
                  onChange={handleChange}
                  options={states.map(state => ({ value: state.name, label: state.display_name }))}
                  placeholder={t('ProfileServiceProvider.State')}
                />

                <EditableSelect
                  label={t('ProfileServiceProvider.City')}
                  name="city"
                  value={userInfo.city}
                  editable={editable["city"]}
                  onEdit={() => handleEdit("city")}
                  onSave={handleSave}
                  onChange={handleChange}
                  options={cities.map(city => ({ value: city.name, label: city.display_name }))}
                  placeholder={t('ProfileServiceProvider.City')}
                />

                <EditableField 
                  label={t('ProfileServiceProvider.Zip')}
                  name="zip"
                  type="text"
                  value={userInfo.zip}
                  editable={editable["zip"]} 
                  onEdit={() => handleEdit("zip")} 
                  onChange={handleChange} 
                  onSave={handleSave}
                  placeholder={t('ProfileServiceProvider.Zip')}
                />
              </div>
              
              {/* Información de Contacto */}
              <div>
                <h5 className="section-title d-flex align-items-center">
                  {t('ProfileServiceProvider.AboutMe')}
                </h5>

                <EditableTextArea 
                  label={t('ProfileServiceProvider.AboutMe')}
                  name="about_me"
                  icon={<BsJournalBookmark />}
                  type="text"
                  value={userInfo.about_me}
                  editable={editable["about_me"]} 
                  onEdit={() => handleEdit("about_me")} 
                  onChange={handleChange} 
                  onSave={handleSave}
                  placeholder={t('ProfileServiceProvider.AboutMe')}
                />
              </div>
            </Col>
          </Row>
        </Form>

        {/* Modal para seleccionar o tomar foto */}
        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t('ProfileServiceProvider.UpdateProfilePicture')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center video-container">
            <div className="d-flex justify-content-center">
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('ProfileServiceProvider.UploadFromDevice')}</Tooltip>}>
                <Button variant="outline-primary me-1" style={{ width: '150px' }} onClick={() => fileInputRef.current.click()}>
                  <BsUpload size={32} />
                  <div><small>{t('ProfileServiceProvider.UploadFromDevice')}</small></div>
                  <Form.Control type="file" ref={frontDocRef} style={{ display: 'none' }} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('ProfileServiceProvider.TakePhoto')}</Tooltip>}>
                <Button variant="outline-primary me-1" style={{ width: '150px' }} onClick={openCamera}>
                  <BsCameraVideoFill size={32} />
                  <div><small>{t('ProfileServiceProvider.TakePhoto')}</small></div>
                  <Form.Control type="file" ref={frontDocRef} style={{ display: 'none' }} />
                </Button>
              </OverlayTrigger>
              
              <Form.Control
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileInput}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal para verificación de identidad */}
        <Modal show={showVerificationModal} onHide={() => setShowVerificationModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t('Generic.IdentityVerification')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>{t('Generic.DocumentType')}</Form.Label>
                <Form.Control as="select" value={documentType} onChange={(e) => setDocumentType(e.target.value)} disabled={verificationProcessStarted||isVerificationButtonDisabled}>
                  <option value="">{t('Generic.Select')}</option>
                  <option value="national_card_id">{t('Generic.NationalIDCard')}</option>
                  <option value="passport">{t('Generic.Passport')}</option>
                  <option value="drive_licence">{t('Generic.DrivingLicense')}</option>
                  <option value="other">{t('Generic.Other')}</option>
                </Form.Control>
              </Form.Group>

              <div className="button-container d-flex justify-content-around mt-2">
                <Button variant="outline-primary me-1" style={{ width: '150px' }} onClick={() => frontDocRef.current.click()} disabled={verificationProcessStarted||isVerificationButtonDisabled}>
                  {frontDocImage ? (
                    <img src={frontDocImage} alt="Front Document" style={{ width: '100px' }} />
                  ) : (
                    <>
                      <BiIdCard size={32} />
                      <div><small>{t('Generic.UploadFrontDocument')}</small></div>
                    </>
                  )}
                  <Form.Control type="file" ref={frontDocRef} style={{ display: 'none' }} onChange={(e) => handleImageChange(e, setFrontDocImage)} />
                </Button>
                
                <Button variant="outline-primary me-1" style={{ width: '150px' }} onClick={() => backDocRef.current.click()} disabled={verificationProcessStarted||isVerificationButtonDisabled}>
                  {backDocImage ? (
                    <img src={backDocImage} alt="Back Document" style={{ width: '100px' }} />
                  ) : (
                    <>
                      <BiCreditCardFront size={32} />
                      <div><small>{t('Generic.UploadBackDocument')}</small></div>
                    </>
                  )}
                  <Form.Control type="file" ref={backDocRef} style={{ display: 'none' }} onChange={(e) => handleImageChange(e, setBackDocImage)} />
                </Button>

                <Button variant="outline-primary" style={{ width: '150px' }} onClick={() => facePhotoRef.current.click()} disabled={verificationProcessStarted||isVerificationButtonDisabled}>
                  {facePhotoImage ? (
                    <img src={facePhotoImage} alt="Back Document" style={{ width: '100px' }} />
                  ) : (
                    <>
                      <PiUserRectangleFill size={32} />
                      <div><small>{t('Generic.UploadFacePhoto')}</small></div>
                    </>
                  )}
                  <Form.Control type="file" ref={facePhotoRef} style={{ display: 'none' }} onChange={(e) => handleImageChange(e, setFacePhotoImage)} />
                </Button>
              </div>

              {verificationStatus && <VerificationStatusBadge status={verificationStatus} />}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowVerificationModal(false)}>
              {t('Generic.Close')}
            </Button>
            <Button variant="primary" onClick={handleSubmitVerification} disabled={verificationProcessStarted||isVerificationButtonDisabled}>
              {t('Generic.StartVerification')}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal para ver la foto de perfil en tamaño oroginal */}
        <Modal show={showLargeImageModal} onHide={() => setShowLargeImageModal(false)} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Profile Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <img src={profileImage} alt="Profile" style={{ maxWidth: '100%', height: 'auto' }} />
          </Modal.Body>
        </Modal>

        {/* Modal para mensajes */}
        <ModalMessage
          show={showModalMessage}
          onHide={() => setShowModalMessage(false)}
          title={modalMessageContent.title}
          message={modalMessageContent.message}
          type={modalMessageContent.type}
        />

        {/* Modal para modificar el email despues que esta verificado */}
        <UpdateEmailModal
          show={showUpdateEmailModal}
          onHide={() => setShowUpdateEmailModal(false)}
          onSubmit={handleSave}
        />

        {/* Modal para modificar el telefono despues que esta verificado */}
        <UpdatePhoneModal
          show={showUpdatePhoneModal}
          onHide={() => setShowUpdatePhoneModal(false)}
          onSubmit={handleSave}
        />

        {/* Modal para mostrar mensaje cuando el usuario queire modificar el pais y ya su perfil esta verificado */}
        {/* Un pais diferente al ya verificado necesitara un nuevo proceso de verificacion */}
        <Modal show={showChangeCountryModal} onHide={() => setShowChangeCountryModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t('ProfileServiceProvider.ConfirmCountryChangeTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('ProfileServiceProvider.ConfirmCountryChangeMessage')}</p>
            <p className="text-danger"><b>{t('ProfileServiceProvider.ConfirmCountryChangeQuestion')}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {setShowChangeCountryModal(false); handleEdit("country")}}>
              {t('ProfileServiceProvider.CancelButton')}
            </Button>
            <Button variant="primary" onClick={confirmCountryChange}>
              {t('ProfileServiceProvider.ContinueButton')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
}

export default ProfileServiceProvider;