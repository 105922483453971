import React, { useState, useEffect } from 'react';

import { fetchNotifications, deleteNotification, markNotificationAsRead } from '../services/TaskiMapApiService';
import { ListGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomConfirmation from '../components/CustomConfirmation';
import { BsTrash, BsArrowUpRight } from 'react-icons/bs';
import { formatDistanceToNow, parseISO } from 'date-fns';
import LoadingScreen from '../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext'; 
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';
import { es, enUS } from 'date-fns/locale';
import '../styles/App.css';

function Communications() {

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userValidated, setUserValidated] = useState(false);
  const [communications, setCommunications] = useState([]);
  const [highlighted, setHighlighted] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const highlightId = parseInt(query.get('id'), 10);
  const [loading, setLoading] = useState(true);
  const highlightType = query.get('type');
  const { validateSession } = useUser();
  const navigate = useNavigate();


  // Highlight selected item
  useEffect(() => {
    if (highlightId && highlightType) {
      const timer = setTimeout(() => {
        setHighlighted(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [highlightId, highlightType]);


  // Map languages to date-fns locales and format time ago
  const localeMap = {
    es: es,
    en: enUS,
    pt: 'pt-BR',
  };


  // Format time ago using date-fns and locale map defined above 
  const formatTimeAgo = (dateString, lng) => {
    const date = parseISO(dateString);
    const locale = localeMap[lng];
    return formatDistanceToNow(date, { addSuffix: true, locale: locale });
  };


  // Fetch communications from API
  const fetchCommunications = async () => {
    try {
      const response = await fetchNotifications('communications');
      setCommunications(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching communications:', error);
      setLoading(false);
    }
  };


  // Delete communication from API
  const handleDelete = async (id) => {
    if (selectedId) {
      try {
        await deleteNotification(selectedId);
        setCommunications(communications.filter(comm => comm.data.id !== selectedId));
        setShowConfirmation(false);
        setSelectedId(null);
      } catch (error) {
        console.error('Error deleting communication:', error);
      }
    }
  };


  // Mark notification as read and navigate to service request
  const handleGoTo = async (id, type) => {
    try {
      await markNotificationAsRead(id);
      if (type === 'serviceRequests') {
        navigate(`/${lng}/service-requests?id=${id}`);
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };


  // Prompt user to confirm deletion of communication
  const promptDeleteConfirmation = (id) => {
    setSelectedId(id);
    setShowConfirmation(true);
  };


  // Fetch communications on component mount when user is validated
  useEffect(() => {
    if (userValidated) {
      fetchCommunications();
    }
  }, [userValidated]);


  // Validate user session on component mount
  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, []);


  if (loading) {
    return <LoadingScreen />;
  }

  function AtionButtons({ elementId, notificationType, onDelete }) {
    return (
      <div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {notificationType === 'serviceRequests' ? t('Communications.GoToServiceRequest') : t('Communications.GoTo')}
            </Tooltip>
          }
        >
          <Button variant="outline-primary" className="ms-1" onClick={() => handleGoTo(elementId, notificationType)} >
            <BsArrowUpRight />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>{t('Communications.DeleteNotification')}</Tooltip>
          }
        >
          <Button variant="outline-danger" className="ms-1" onClick={() => onDelete(elementId)}>
            <BsTrash />
          </Button>
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="m-3 flex-grow-1">
        <h1>{t('Communications.CommunicationCenter')}</h1>
        {communications.length > 0 ? (
          <ListGroup>
            {communications.map(comm => (
              <ListGroup.Item 
                key={comm.data.id}
                className="d-flex justify-content-between align-items-center comunications-list-group-item"
                style={{
                  backgroundColor: comm.notification_type === highlightType && comm.data.id === highlightId && highlighted ? '#ffffe0' : 'transparent',
                  transition: 'background-color 1s ease-out'
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={comm.data.user.data_user.img_avatar || 'https://via.placeholder.com/150'}
                    alt="Avatar"
                    className="rounded-circle me-3"
                    style={{ width: '50px', height: '50px', border: 'solid 1px #C0C0C0' }}
                  />
                  <div>
                    <strong>{comm.data.user.data_user.first_name} {comm.data.user.data_user.last_name}</strong> - {comm.data.message}
                    <div><small>{formatTimeAgo(comm.data.created_at, lng)}</small></div>
                    <div><small className="badge badge-main-yellow">{t('Communications.' + comm.notification_type)}</small></div>
                  </div>
                </div>

                <div className="text-end">
                  {<AtionButtons notificationType={comm.notification_type} elementId={comm.data.id} onDelete={() => promptDeleteConfirmation(comm.data.id)} />}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <div>{t('Communications.NoCommunicationsFound')}</div>
        )}
      </div>

      <CustomConfirmation
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleDelete}
        title={t('Communications.ConfirmDeleteTitle')}
        message={t('Communications.ConfirmDeleteMessage')}
        variant="danger"
      />

    </div>
  );
}

export default Communications;

