import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import LoginForm from './LoginForm';


const LoginModal = ({ show, onHide, onCancel, callBackAction }) => {

    const { t } = useTranslation();
    
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
              <Modal.Title>{t('LoginButton.Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginForm
                    onClose={onCancel}
                    callBackAction={callBackAction}
                />
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;