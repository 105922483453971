import React, { useState, useEffect } from 'react';

import { FaUserEdit, FaHome, FaToolbox, FaStar, FaSignOutAlt, FaTasks, FaBroadcastTower, FaFileInvoiceDollar, FaFileContract, FaCog } from 'react-icons/fa';
import { PiArrowFatLineLeftLight, PiArrowFatLineRightLight } from "react-icons/pi";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { fetchNotifications } from '../services/TaskiMapApiService';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext'; 
import { FaRegMessage } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/App.css';

function Sidebar() {

  const { t, i18n } = useTranslation();

  const [newServiceRequestsCount, setNewServiceRequestsCount] = useState(0);
  const [userValidated, setUserValidated] = useState(false);
  const { user, logout, validateSession } = useUser();
  const [collapsed, setCollapsed] = useState(false);
  const userType = user?.data_user?.user_type;
  const lng = i18n.language.split('-')[0];
  const location = useLocation();
  const navigate = useNavigate();
  

  useEffect(() => {
    const isCollapsed = localStorage.getItem('sidebarCollapsed') === 'true';
    setCollapsed(isCollapsed);
  }, []);


  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('sidebarCollapsed', !collapsed);
  };


  const links = [
    { path: `/${lng}/dashboard`, label: t('Sidebar.Dashboard'), icon: <FaHome />, renderOn: ['client', 'professional', 'mixed'] },
    { path: `/${lng}/edit-profile`, label: t('Sidebar.EditProfile'), icon: <FaUserEdit />, renderOn: ['client', 'professional', 'mixed'] },
    { type: 'divider', key: 'divider-1' },
    { path: `/${lng}/service-requests`, label: t('Sidebar.Requests'), icon: <FaTasks />, notificationCount: newServiceRequestsCount, renderOn: ['client', 'professional', 'mixed'] },
    // { path: `/${lng}/proposals`, label: t('Sidebar.Proposals'), icon: <FaFileContract />, renderOn: ['client', 'professional', 'mixed'] },
    { path: `/${lng}/services`, label: t('Sidebar.Services'), icon: <FaToolbox />, renderOn: ['professional', 'mixed'] },
    // { path: `/${lng}/invoices`, label: t('Sidebar.Invoices'), icon: <FaFileInvoiceDollar />, renderOn: ['client', 'professional', 'mixed'] },
    { type: 'divider', key: 'divider-2' },
    { path: `/${lng}/reviews`, label: t('Sidebar.Reviews'), icon: <FaStar />, renderOn: ['client', 'professional'] },
    { path: `/${lng}/messenger`, label: t('Sidebar.Messenger'), icon: <FaRegMessage />, renderOn: ['client', 'professional', 'mixed'] },
    { path: `/${lng}/communications`, label: t('Sidebar.Coms'), icon: <FaBroadcastTower />, renderOn: ['client', 'professional', 'mixed'] },
    { type: 'divider', key: 'divider-3' },
    { path: `/${lng}/account-settings`, label: t('Sidebar.AccountSettings'), icon: <FaCog />, renderOn: ['client', 'professional', 'mixed'] }
  ];


  const getNotifications = async () => {
    try {
      const response = await fetchNotifications('notifications');
      const serviceRequests = response.data.data.filter(n => n.notification_type === 'serviceRequests' && !n.data.seen_by_provider);
      setNewServiceRequestsCount(serviceRequests.length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };


  const handleLogout =  () => {
    logout();
    navigate(`/${lng}`);
  };


  useEffect(() => {
    if (userValidated) {
      getNotifications();
    }
  }, [userValidated]);


  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, []);


  const renderTooltip = (props, label) => (
    <Tooltip id="button-tooltip" {...props}>
      {label}
    </Tooltip>
  );

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <Link to={`/${lng}`} className="sidebar-logo-link">
        <h4>TaskiMap</h4>
      </Link>
      <button onClick={toggleSidebar} className="collapse-button">
        {collapsed ? <PiArrowFatLineRightLight /> : <PiArrowFatLineLeftLight />}
      </button>
      {links.map(link => {
        if (link.type === 'divider') {
          return <div className="sidebar-divider" key={link.key}></div>;
        } else if (link.renderOn.includes(userType)) {
          return (
            <OverlayTrigger
              key={link.path}
              placement="right"
              overlay={collapsed ? renderTooltip(undefined, link.label) : <></>}
            >
              <Link
                to={link.path}
                className={`sidebar-link ${location.pathname === link.path ? 'active' : ''}`}
              >
                {link.icon} {!collapsed && link.label}
                {link.notificationCount > 0 && (
                  <span className="menu-notification-badge">{link.notificationCount}</span>
                )}
              </Link>
            </OverlayTrigger>
          );
        }
        return null;
      })}
      <Link
        className={`sidebar-link ${collapsed ? '' : 'd-flex align-items-center'}`}
        onClick={handleLogout}
        style={{ marginTop: 'auto', width: '100%', textAlign: 'center', fontWeight: 'bold' }}
      >
        <FaSignOutAlt className="me-2" /> {!collapsed && t('Sidebar.Logout')}
      </Link>
    </div>
  );
}

export default Sidebar;

