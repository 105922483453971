import React, { createContext, useContext, useEffect, useState } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  
  let socket = null;

  const [ws, setWs] = useState(null);

  useEffect(() => {
    let shouldReconnect = true;

    const connectWebSocket = () => {
      socket = new WebSocket(process.env.REACT_APP_TASKIMAP_WS_BASE_URL);

      socket.onopen = () => {
        console.log(`Connected to WebSocket ${process.env.REACT_APP_TASKIMAP_WS_BASE_URL}`);
        setWs(socket); // Establece el WebSocket en el estado
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = () => {
        console.log('Disconnected from WebSocket');
        if (shouldReconnect) {
          console.log('Attempting to reconnect...');
          setTimeout(connectWebSocket, 5000);
        }
      };
    };

    // Agrega una espera antes de conectar el WebSocket
    const delayBeforeConnecting = 2000;

    const timer = setTimeout(() => {
      connectWebSocket();
    }, delayBeforeConnecting);

    // Limpieza al desmontar el componente o cuando cambie el efecto
    return () => {
      shouldReconnect = false; // Evitar reconexión cuando el componente se desmonte
      clearTimeout(timer); // Limpiar el timeout si el componente se desmonta antes de que termine la espera
      if (socket) {
        socket.close(); // Cierra la conexión cuando el componente se desmonte
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);

