import React, { useState, useCallback, useEffect, useRef } from 'react';

import { fetchChats, fetchNotifications, fetchUnreadChats, markNotificationAsRead } from '../services/TaskiMapApiService';
import { Nav, Navbar, NavDropdown, Button, Spinner, Modal, FormControl, Dropdown, Offcanvas } from 'react-bootstrap';
import { BsGeoAltFill, BsBell, BsEnvelope } from 'react-icons/bs';
import { useWebSocket } from '../contexts/WebSocketContext'; 
import RegisterButton from '../components/RegisterButton';
import { Link, useNavigate } from 'react-router-dom';
import LoginButton from '../components/LoginButton';
import { useSound } from '../contexts/SoundContext';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next'; 
import { format, parseISO } from 'date-fns';

function Header({ 
  showTitle, 
  showRegisterButtom=true, 
  showSearchBar=false, 
  onSearch, 
  searchKeyword, 
  setSearchKeyword, 
  handleKeyDown,
  handleLogout,
  requestLocationPermission,
  loadingLocation,
  currentLocation
}) {

  const { t, i18n } = useTranslation();
  const { user, validateSession } = useUser();
  const ws = useWebSocket();

  const { playNewMessageSound, playNewChatSound, playNewMessageChatSound } = useSound();
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [unreadChatsCount, setUnreadChatsCount] = useState(0);
  const [userValidated, setUserValidated] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadChats, setUnreadChats] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const lng = i18n.language.split('-')[0];
  const navigate = useNavigate();


  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);


  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);


  const handleNotificationClick = async (id, type) => {
    await markNotificationAsRead(id);
    navigate(`/${lng}/communications?id=${id}&type=${type}`);
  };


  const handleChatClick = (id) => {
    navigate(`/${lng}/messenger?id=${id}`);
  };


  const handleGoToNotificationCenter = () => {
    navigate(`/${lng}/communications`);
  };


  const handleGoToMessenger = () => {
   navigate(`/${lng}/messenger`); 
  }

  
  const getNotifications = async () => {
    try {
      const response = await fetchNotifications('notifications');
      setNotifications(response.data.data);
      setNewNotificationsCount(response.data.data.filter(n => n.data.seen_by_provider === 0).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };


  const getUnreadChats = async () => {
    try {
      const response = await fetchUnreadChats();
      const chatsWithUnreadMessages = response.data.data.filter(chat => chat.unread_count > 0);
      setUnreadChats(chatsWithUnreadMessages);
      const totalUnread = chatsWithUnreadMessages.reduce((acc, chat) => acc + chat.unread_count, 0);
      setUnreadMessagesCount(totalUnread);
      setUnreadChatsCount(chatsWithUnreadMessages.length);

      if(totalUnread > 0) {
        playNewMessageChatSound();
      }
    } catch (error) {
      console.error('Error fetching unread chats:', error);
    }
  };


  const getChats = async () => {
    try {
      const response = await fetchChats();
      // Verifica que la conexión WebSocket esté abierta antes de enviar mensajes
      if (ws && ws.readyState === WebSocket.OPEN) {
        response.data.data.forEach(chat => {
          ws.send(JSON.stringify({ type: 'join_chat', chatId: chat.id, userId: user.id }));
        });
      }

    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  }


  useEffect(() => {
    if (user && ws && ws.readyState === WebSocket.OPEN) {
      getChats();
      getNotifications();
      getUnreadChats();
    }
  }, [user, userValidated, ws]);

  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, []);


  useEffect(() => {
    const handleMessage = event => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'new_message' || data.type === 'new_chat') {
          getUnreadChats();
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    const addMessageHandler = () => {
      try {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.addEventListener('message', handleMessage);
        }
      } catch (error) {
        console.error('Error adding WebSocket message handler:', error);
      }
    };

    // Agregar el manejador cuando el WebSocket esté listo
    addMessageHandler();

    // Retirar el manejador cuando el componente se desmonte o el WebSocket cambie
    return () => {
      try {
        if (ws) {
          ws.removeEventListener('message', handleMessage);
        }
      } catch (error) {
        console.error('Error removing WebSocket message handler:', error);
      }
    };
  }, [ws]);


  return (
    <>
      
      {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} style={{ marginLeft: '10px', marginRight: '10px' }}>
            
            {(showTitle || showSearchBar) && (
              <Navbar.Brand as={Link} to="/">
                <h2 className="mb-0 title-header">{t('App.Title')}</h2>
              </Navbar.Brand>
            )}

            <div className="header-geo">
              <BsGeoAltFill />
              {loadingLocation ? (
                <Spinner animation="border" size="sm" className="ms-2" />
              ) : (
                !currentLocation ? (
                  <Button variant="outline-secondary" className="ms-2" onClick={handleOpenModal}>{t('Header.ShowLocation')}</Button>
                ) : (
                  <span>{currentLocation}</span>
                )
              )}
            </div>


            {showSearchBar && (
              <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                <FormControl
                  type="text"
                  placeholder={t('Search')}
                  className="me-2"
                  value={searchKeyword}
                  onChange={e => setSearchKeyword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={{ width: '350px' }}
                />
                <Button variant="outline-primary" onClick={onSearch}>{t('Search')}</Button>
              </div>
            )}

            {/*{showTitle && (
              <Navbar.Brand as={Link} to="/" className="flex-grow-1 text-center">
                <h2 className="mb-0 title-header">{t('App.Title')}</h2>
              </Navbar.Brand>
            )}*/}
            
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {t('App.Title')}
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                {user ? (
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    {/*Messages*/}
                    <NavDropdown
                      title={
                        <>
                          <BsEnvelope style={{ fontSize: '1.5em', cursor: 'pointer' }} />
                          {unreadMessagesCount > 0 && (
                            <div className="position-absolute badge rounded-pill bg-danger notification-circle-bounce" style={{ left: '-7px', cursor: 'pointer' }}>
                              {unreadChatsCount}
                            </div>
                          )}
                        </>
                      }
                      id="notification-dropdown"
                      align="end"
                    >
                      {unreadChats.map(chat => (
                        chat.unread_count > 0 && (
                          <NavDropdown.Item key={chat.id} onClick={() => handleChatClick(chat.id)}>
                            {chat.message_from}
                            <div style={{ fontSize: '0.75rem', textAlign: 'right', color: '#888' }}>
                              {format(parseISO(chat.latest_message_at), 'MMM dd, yyyy, h:mm a')}
                            </div>
                          </NavDropdown.Item>
                        )
                      ))}
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={handleGoToMessenger}>
                        {t('Header.GoToMessenger')}
                      </NavDropdown.Item>
                    </NavDropdown>

                    {/*Notifications*/}
                    <NavDropdown
                      title={
                        <>
                          <BsBell style={{ fontSize: '1.5em', cursor: 'pointer' }} />
                          {newNotificationsCount > 0 && (
                            <div className="position-absolute badge rounded-pill bg-danger notification-circle-bounce" style={{ left: '-7px', cursor: 'pointer' }}>
                              {newNotificationsCount}
                            </div>
                          )}
                        </>
                      }
                      id="notification-bell-dropdown"
                      align="end"
                    >
                      {notifications.map(notification => (
                        <NavDropdown.Item key={notification.data.id} onClick={() => handleNotificationClick(notification.data.id, notification.notification_type)}>
                          {notification.message}
                          <div style={{ fontSize: '0.75rem', textAlign: 'right', color: '#888' }}>
                            {format(parseISO(notification.data.created_at), 'MMM dd, yyyy, h:mm a')}
                          </div>
                        </NavDropdown.Item>
                      ))}
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={handleGoToNotificationCenter}>
                        {t('Header.GoToNotificationCenter')}
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title={user.email} id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to={`/${lng}/dashboard`}>{t('Header.Dashboard')}</NavDropdown.Item>
                      <NavDropdown.Item onClick={handleLogout}>{t('Header.Logout')}</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>

                ) : (
                  <>
                    <Nav className="justify-content-end flex-grow-1 pe-3"></Nav>
                    {showRegisterButtom && <RegisterButton />}
                    <LoginButton />
                  </>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>

        </Navbar>
      ))}


      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Header.LocationPermissionTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('Header.LocationPermissionRequestText')}</p>
            <div className="d-flex justify-content-end">
                <Button variant="primary" onClick={requestLocationPermission}>{t('Header.AllowLocationPermission')}</Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

    </>
  );
}

export default Header;
