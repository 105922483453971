import React, { useState } from 'react';

import { Modal, ListGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import 'react-country-flag';
import '../styles/App.css';

const LANGUAGES = [
  { code: 'en', name: 'English', flag: '🇬🇧' }, 
  { code: 'es', name: 'Español', flag: '🇪🇸' }, 
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'pt', name: 'Português', flag: '🇵🇹' },
  // { code: 'fr', name: 'Français', flag: '🇫🇷' },
  // { code: 'de', name: 'Deutsch', flag: '🇩🇪' }, 
  // { code: 'ru', name: 'Русский', flag: '🇷🇺' }, 
  // { code: 'ja', name: '日本語', flag: '🇯🇵' }, 
  // { code: 'zh', name: '中文', flag: '🇨🇳' },   
  // { code: 'ko', name: '한국어', flag: '🇰🇷' }  
];

function LanguageSwitcher() {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language.split('-')[0];
  
  const availableLanguages = LANGUAGES.filter(lang => lang.code !== currentLanguage);
  const randomLanguages = availableLanguages.sort(() => 0.5 - Math.random()).slice(0, 3);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();


  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    navigate(`/${code}`);
    handleClose();
  };

  return (
    <div>
      <div className="languages-section text-center mt-5">
        <p>{t('LanguageSwitcher.Prompt')}:
          {randomLanguages.map(lang => (
            <Button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className="link-button"
              style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'none', cursor: 'pointer' }}
            >
              <span role="img" aria-label={lang.name} className="me-2">{lang.flag}</span>
              {lang.name}
            </Button>
          ))}
          {/*<Button
            onClick={handleShow}
            className="link-button text-decoration-none"
            style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {t('LanguageSwitcher.More')}
          </Button>*/}
        </p>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('LanguageSwitcher.SelectLanguage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {LANGUAGES.map(lang => (
              <ListGroup.Item
                key={lang.code}
                active={lang.code === currentLanguage}
                onClick={() => changeLanguage(lang.code)}
                action
                className="d-flex align-items-center"
              >
                <span role="img" aria-label={lang.name} className="me-2">{lang.flag}</span>
                {lang.name} {lang.code === currentLanguage && '✓'}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LanguageSwitcher;

