import React from 'react';

import { ListGroupItem, Carousel, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { FaUser, FaTag, FaMoneyBillWave, FaTools, FaClock } from 'react-icons/fa';
import UserProfilePopover from './UserProfilePopover';
import { useTranslation } from 'react-i18next';
import { MdVerified } from "react-icons/md";
import RatingDisplay from './RatingDisplay';

const SearchResultItem = ({ service, handleRequestService, handleRequestQuote, handleSendMessage, isServiceRequested }) => {
  
  const { t } = useTranslation();

  const ratingValue = parseFloat(service.average_rating) || 0;
  const ratingCount = service.ratings_count || 0;

  const isServiceRequestedOrAccepted = service.service_requests?.some(sr => 
    // (sr.status === 'requested' || sr.status === 'accepted') && sr.user_id === currentUserId
    sr.status === 'requested' || sr.status === 'accepted'
  );

  // Función para formatear distancia
  const formatDistance = (distance) => {
    return (typeof distance === "number" && !isNaN(distance)) ? distance.toFixed(2) : '0.00';
  };

  // Renderiza el carrusel de imágenes
  const renderCarousel = (files) => {
    if (files && files.length > 0) {
      return (
        <Carousel>
          {files.map((file, index) => (
            <Carousel.Item key={index}>
              <img className="d-block w-100" src={file.url} alt={`Slide ${index + 1}`} style={{ height: '200px', width: 'auto' }} />
            </Carousel.Item>
          ))}
        </Carousel>
      );
    } else {
      return <div className="text-center p-3" style={{ width: '200px', height: '200px' }}><span>No image available</span></div>;
    }
  };

  // Determina el color basado en la distancia
  const getColorByDistance = (distance) => {
    if (distance < 2) return "bg-success";
    if (distance >= 2 && distance <= 5) return "bg-primary";
    return "bs-warning-bg-subtle";
  };

  // Renderiza el icono de verificación
  const renderVerificationIcon = (isVerified) => {
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-verification-${service.id}`}>{isVerified ? t('SearchResultItem.Verified') : t('SearchResultItem.NotVerified')}</Tooltip>}>
        <div style={{ display: 'inline-flex', alignItems: 'center', left: '-7px', position: 'relative' }}>
          <MdVerified color={isVerified ? 'orange' : 'grey'} style={{ marginLeft: '5px', fontSize: '20px' }} />
        </div>
      </OverlayTrigger>
    );
  };

  // Renderiza botones con tooltips
  const renderActionButton = (id, message, tooltipMessage, actionOnClic) => (
    <OverlayTrigger key={id} placement="top" overlay={<Tooltip id={`tooltip-${id}`}>{tooltipMessage}</Tooltip>}>
      <Button 
        variant="btn btn-outline-secondary" 
        size="sm" 
        className={`me-2`}
        onClick={actionOnClic}
      >
        {message}
      </Button>
    </OverlayTrigger>
  );

  // Renderiza los horarios
  const renderSchedule = (schedules) => {
    // Asignar un número a cada día de la semana para facilitar la ordenación
    const dayOrder = {
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
      'Sunday': 7
    };

    // Ordenar los horarios por día de la semana
    const sortedSchedules = schedules.sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);

    // Renderizar la lista ordenada de horarios
    return (
      <ul className="list-unstyled">
        {sortedSchedules.map((schedule, index) => (
          <li key={index}>{`${t('SearchResultItem.' + schedule.day)}: ${schedule.time_start} - ${schedule.time_end}`}</li>
        ))}
      </ul>
    );
  };

  const renderRequestServiceButton = () => {
    if (isServiceRequestedOrAccepted || isServiceRequested) {
      return (
        <Button variant="secondary" size="sm" className="me-2" disabled>
          {t('SearchResultItem.ServiceRequested')}
        </Button>
      );
    } else {
      return renderActionButton("request", t('SearchResultItem.RequestService'), t('SearchResultItem.RequestAService'), handleRequestService);
    }
  };

  return (
    <ListGroupItem 
      className="d-flex justify-content-between align-items-start search-result-item-list-group-item-hover"
      style={{ borderBottom: '1px solid #dee2e6', borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderRadius: '0' }}
    >
      <div style={{ width: '200px' }}>{renderCarousel(service.files)}</div>
      <div className="ms-3 flex-grow-1 position-relative">
        <h5 className="fw-bold">{service.name}</h5>
        <p>{service.description}</p>

        <div id={`popover-profile-container-${service.id}`} style={{ position: 'relative' }}>
          <FaUser style={{ marginRight: '5px' }} />
          <strong>{t('SearchResultItem.Representative')}: </strong>
          <OverlayTrigger 
            trigger={['hover', 'focus']}
            placement="auto"
            overlay={<UserProfilePopover user={service.user} />}
            container={() => document.getElementById(`popover-profile-container-${service.id}`)}
          >
            <span style={{ color: 'blue', cursor: 'pointer', display: 'inline-flex', alignItems: 'center', padding: '2px' }}>
              {service.user.data_user.first_name} {service.user.data_user.last_name}
            </span>
          </OverlayTrigger>
          {renderVerificationIcon(service.user.data_user.identity_verified === 1)}
        </div>

        <div>
          <FaTag style={{ marginRight: '5px' }} />
          <strong>{t('SearchResultItem.Category')}: </strong>
          {service.category.display_name}
        </div>

        <div>
          <FaTools style={{ marginRight: '5px' }} />
          <strong>{t('SearchResultItem.RateType')}: </strong>
          {service.rate_type.display_name}
        </div>

        <div>
          <FaMoneyBillWave style={{ marginRight: '5px' }} />
          <strong>{t('SearchResultItem.Price')}: </strong>
          {service.unit_price}

          {(service.user.system_preferences && 
            service.user.system_preferences.currency !== undefined ) && 
            <span><b><small> {service.user.system_preferences.currency}</small></b></span>
          }

        </div>

        {service.schedules && service.schedules.length > 0 && 
          <div className="mt-2">
            <FaClock style={{ marginRight: '5px' }} />
            <strong>{t('SearchResultItem.Schedule')}:</strong> 
            {renderSchedule(service.schedules)}
          </div>
        }


        <div className="position-absolute top-0 end-0">
          <span className={`badge rounded-pill ${getColorByDistance(service.distance_in_km)}`}>
            {t('SearchResultItem.ServiceDistance', { distance: formatDistance(service.distance_in_km) })}
          </span>
          <div className="mt-2">
            <RatingDisplay ratingValue={ratingValue} ratingCount={ratingCount} />
          </div>
        </div>
        

        <div className="position-absolute bottom-0 end-0 p-2">
          {/* Render message button only if user has chat messages enabled in system preferences */}
          {(!service.user.system_preferences || 
            service.user.system_preferences.enable_chat_messages === undefined || 
            service.user.system_preferences.enable_chat_messages === 1) && 
            renderActionButton("message", t('SearchResultItem.Message'), t('SearchResultItem.SendMessage'), handleSendMessage)
          }

          {/*{renderActionButton("quote", t('SearchResultItem.Quote'), t('SearchResultItem.RequestAQuote'), handleRequestQuote)}*/}
          
          {renderRequestServiceButton()}
        </div>

      </div>
    </ListGroupItem>
  );
};

export default SearchResultItem;

