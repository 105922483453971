import React, { useState, useEffect } from 'react';

import { sendEmailVerificationCode, validateEmailVerificationCode } from '../services/TaskiMapApiService';
import { Button, Modal, Form } from 'react-bootstrap';
import ModalMessage from './ModalMessage';

function BtnEmailVerification({ isVerified, email, onVerificationSuccess }) {

  const [modalMessageContent, setModalMessageContent] = useState({ title: '', message: '', type: 'info' });
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(90);


  /**
   * Manages the countdown timer for the resend verification code button,
   * automatically resetting and deactivating the timer when it reaches zero.
   */
  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            return 90; // Reset timer
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTimerActive]);


  /**
   * Sanitizes error messages from the server response to ensure user-friendly text is displayed.
   * @param {Object} error - The error object from the catch block of an API request.
   * @returns {String} - A cleaned error message suitable for user display.
   */
  const sanitizeErrorMessage = (error) => {
    let errorMessage = 'Error verifying phone code';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    return errorMessage;
  };


  /**
   * Initiates sending an email verification code.
   * On successful API response, it activates a countdown timer.
   * On failure, it shows an error message using ModalMessage.
   */
  const sendVerificationCode = async () => {
    try {
      const response = await sendEmailVerificationCode();
      if (response.data.status) {
        setIsTimerActive(true);
      } else {
        setModalMessageContent({
          title: 'Error',
          message: response.data.message || 'Failed to send verification code',
          type: 'error'
        });
        setShowModalMessage(true);
      }
    } catch (error) {
      setModalMessageContent({
        title: 'Error',
        message: sanitizeErrorMessage(error),
        type: 'error'
      });
      setShowModalMessage(true);
    }
  };


  /**
   * Verifies the email code input by the user against the server.
   * Displays a success message on successful verification or an error message on failure.
   */
  const verifyEmailCode = async () => {
    try {
      const response = await validateEmailVerificationCode(verificationCode);
      if (response.data.status) {
        setModalMessageContent({
          title: 'Success',
          message: 'Email verified successfully.',
          type: 'success'
        });
        setShowModalMessage(true);
        onVerificationSuccess();
        setShowModal(false);
      } else {
        setModalMessageContent({
          title: 'Error',
          message: response.data.message || 'Invalid verification code',
          type: 'error'
        });
        setShowModalMessage(true);
      }
    } catch (error) {
      setModalMessageContent({
        title: 'Error',
        message: sanitizeErrorMessage(error),
        type: 'error'
      });
      setShowModalMessage(true);
    }
  };


  return (
    <>
      {!isVerified && (
        <Button variant="outline-primary" onClick={() => setShowModal(true)} className="mb-1 btn-sm">
          Verify Email
        </Button>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Enter Verification Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Code"
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
                disabled={!isTimerActive}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={sendVerificationCode} disabled={isTimerActive}>
            {isTimerActive ? `${timer}s` : 'Send Code'}
          </Button>
          <Button variant="primary" onClick={verifyEmailCode} disabled={!isTimerActive}>
            Validate Code
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mensajes */}
      <ModalMessage
        show={showModalMessage}
        onHide={() => setShowModalMessage(false)}
        title={modalMessageContent.title}
        message={modalMessageContent.message}
        type={modalMessageContent.type}
      />
    </>
  );
}

export default BtnEmailVerification;

