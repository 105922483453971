import React, { useRef, useEffect, useState } from 'react';

import { InputGroup, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TfiPencil, TfiSave } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';

const EditableSelect = ({ label, labelHtml, name, type, icon, value, editable, onEdit, onSave, onChange, placeholder, showEditButton=true, options }) => {
  
  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState(value || '');
  const [isEditing, setIsEditing] = useState(false);
  const blurTimeoutRef = useRef(null);
  const inputRef = useRef(null);
  
  useEffect(() => {
    setCurrentValue(value || '');
  }, [value]);

  useEffect(() => {
    if (editable && !isEditing) {
      setIsEditing(true);       // Establecer que estamos en modo de edición
      inputRef.current?.focus(); // Asegurarse de enfocar el input cuando se hace editable
    } else if (isEditing && !editable) {
      setIsEditing(false);      // Restablecer el estado de edición cuando se desactiva
    }
  }, [editable, value, isEditing]);


  const handleSelectChange = (e) => {
    setCurrentValue(e.target.value);
    onChange(e);
  };
  
  useEffect(() => {
    return () => {
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current);
      }
    };
  }, []);


  const handleSaveClick = (event) => {
    onEdit();
    event.preventDefault();
    onSave(name, value, () => {
      setIsEditing(false);
    });

    // Limpiar el input cuando se hace save, me aseguro que quede con el valor nuevo
    blurTimeoutRef.current = setTimeout(() => {
      onChange({ target: { name, value: value } });
      // setLoading(false);
    }, 400);
  };

  const renderButton = () => (
    <OverlayTrigger
      key={editable ? 'save' : 'edit'}
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${editable ? 'save' : 'edit'}`}>
          {editable ? `${t('EditableField.Save')} ${label}` : `${t('EditableField.Edit')} ${label}`}
        </Tooltip>
      }
    >
      <Button 
        variant="outline-secondary"
        onClick={editable ? handleSaveClick : () => onEdit(name)}
        className="ms-auto"
        style={{ borderRadius: '15px', padding: '10px' }}
      >
        {editable ? <TfiSave style={{ fontSize: '1.5rem' }} /> : <TfiPencil style={{ fontSize: '1.5rem' }} />}
      </Button>
    </OverlayTrigger>
  );

  return (
    <>
      <InputGroup className="mt-1" style={{ height: '25px' }}>
        {icon && (
          <InputGroup.Text style={{ border: 'none', background: 'none', fontSize: '1.5rem' }}>
            {icon}
          </InputGroup.Text>
        )}

        {!editable ? (
          <Form.Control
            type="text"
            readOnly={true}
            value={options.find(option => option.value === currentValue)?.label || placeholder}
            style={{ border: 'none', height: '25px', marginLeft: icon ? '0' : '47px', borderRadius: '5px' }}
          />
        ) : (
          <Form.Select
              ref={inputRef}
              name={name}
              value={currentValue}
              onChange={onChange}
              onBlur={() => setIsEditing(false)}
              className="gradientLeftToRightLightGray"
              style={{ border: 'none', marginLeft: icon ? '0' : '47px', borderRadius: '5px' }}
            >
              <option value="">{t('EditableSelect.Select')}</option>
              {options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Form.Select>
        )}

        { showEditButton && ( renderButton() )}

      </InputGroup>

      <Form.Text style={{ marginLeft: '60px', fontSize: '0.8rem', color: 'lightgray' }}>
        {labelHtml ? <span dangerouslySetInnerHTML={{ __html: labelHtml }} /> : label}
      </Form.Text>

    </>
  );
}

export default EditableSelect;

