import React, { useState, useEffect } from 'react';

import { MapContainer, TileLayer, Marker, Circle, useMapEvents } from 'react-leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Corrige los íconos por defecto de los marcadores
delete L.Icon.Default.prototype._getIconUrl;

// Establece los íconos por defecto de los marcadores
L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

// Componente de marcador de ubicación
function LocationMarker({ center, radius, onCenterChange }) {
  const [position, setPosition] = useState(center);
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      onCenterChange(e.latlng);
      map.flyTo(e.latlng, map.getZoom()); // Optionally recenter the map on the new marker
    }
  });

  useEffect(() => {
    setPosition(center);
    map.flyTo(center, map.getZoom());
  }, [center, map]);

  return position === null ? null : (
    <Marker position={position}>
      <Circle center={position} radius={radius} />
    </Marker>
  );
}

// Componente de selección de ubicación con mapa
function MapPicker({ center, radius, onCenterChange, onRadiusChange }) {
  return (
    <MapContainer center={center} zoom={14} style={{ height: '400px', width: '100%' }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LocationMarker center={center} radius={radius} onCenterChange={onCenterChange} />
    </MapContainer>
  );
}

export default MapPicker;

