import axios from 'axios';

const taskiApi = axios.create({
  baseURL: process.env.REACT_APP_TASKIMAP_API_BASE_URL,
});

console.log(process.env.REACT_APP_TASKIMAP_API_BASE_URL);

taskiApi.interceptors.request.use(
  (config) => {
    // Verificar si estamos enviando un FormData (carga de archivos)
    if (!(config.data instanceof FormData)) {
      config.headers['Content-Type'] = 'application/json';
    }

    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

taskiApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      localStorage.removeItem('authToken');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default taskiApi


/**
 * login
 *
 * Authenticates the user with the provided credentials.
 *
 * @param {Object} credentials - An object containing the user's login credentials (e.g., email and password).
 * @returns {Promise} A promise that resolves with the response from the API when the login is successful.
 */
export const tryLogin = (credentials) => taskiApi.post('/v1/login', credentials);


/**
 * tryLogout
 *
 * Logs out the current user by invalidating the session on the server.
 *
 * @returns {Promise} A promise that resolves with the response from the API when the logout is successful.
 */
export const tryLogout = () => taskiApi.post('/v1/logout');

/**
 * validateSession
 *
 * Validates the current user session to ensure the user is still authenticated.
 *
 * @returns {Promise} A promise that resolves with the response from the API if the session is valid.
 */
export const validateUserSession = () => taskiApi.get('/v1/validate_session');


/**
 * registerClient
 *
 * Registers a new client using the provided form data.
 *
 * @param {Object} formData - An object containing the registration details (e.g., name, email, password).
 * @returns {Promise} A promise that resolves with the response from the API when the registration is successful.
 */
export const tryRegisterClient = (formData) => taskiApi.post('/v1/register_client', formData);


/**
 * tryRegisterProfessional
 *
 * Registers a new professional client with the provided form data.
 *
 * @param {Object} formData - The form data containing the details required for registering a professional client.
 * @returns {Promise} A promise that resolves with the response from the API upon successful registration.
 */
export const tryRegisterProfessional = (formData) => taskiApi.post('/v1/register_professional', formData);


/**
 * fetchChats
 *
 * Retrieves all chats where the logged-in user is a participant.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of chat objects.
 */
export const fetchChats = () => taskiApi.get('/v1/chats');


/**
 * profileVerificationProcess
 *
 * Submits the user's profile verification data, including identity document images and a face photo.
 *
 * @param {Object} payload - The verification data payload.
 * @param {string} payload.identity_document_type - The type of identity document (e.g., passport, driver's license).
 * @param {File} payload.img_identity_document_front - The front image of the identity document.
 * @param {File} payload.img_identity_document_back - The back image of the identity document.
 * @param {File} payload.img_face_photo - A photo of the user's face.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const profileVerificationProcess = (payload) => taskiApi.post('/v1/user/verification_process', payload);


/**
 * fetchVerificationStatus
 *
 * Retrieves the current status of the user's profile verification process.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing the verification status.
 */
export const fetchVerificationStatus = () => taskiApi.get('/v1/user/verification_process_status');


/**
 * fetchMessages
 *
 * Fetches all messages for a specific chat where the logged-in user is a participant.
 *
 * @param {number|string} chatId - The ID of the chat from which to retrieve messages.
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of message objects.
 */
export const fetchMessages = (chatId) => taskiApi.get(`/v1/chat/${chatId}/messages`);


/**
 * markMessagesAsRead
 *
 * Marks all unread messages in a specific chat as read by the logged-in user.
 *
 * @param {number|string} chatId - The ID of the chat for which the messages should be marked as read.
 * @returns {Promise} A promise that resolves with the response from the API when the operation is successful.
 */
export const markMessagesAsRead = (chatId) => taskiApi.post('/v1/message-set-as-read', { chat_id: chatId });


/**
 * sendMessage
 *
 * Sends a message to a specific chat. Note that this function is currently not used in the web application, 
 * as messages are being stored directly in the database through WebSocket communication.
 *
 * @param {number|string} chatId - The ID of the chat to which the message should be sent.
 * @param {string} message - The content of the message to be sent.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const sendMessage = (chatId, message) => taskiApi.post('/v1/messages', { chat_id: chatId, message: message });


/**
 * fetchNotifications
 *
 * Fetches all service request notifications that the logged-in user has not read yet.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of notification objects.
 */
export const fetchNotifications = (toRenderOn) => taskiApi.get(`/v1/notifications?toRenderOn=${toRenderOn}`);


/**
 * markNotificationAsRead
 *
 * Marks a specific notification as read by the logged-in user.
 *
 * @param {number|string} notificationId - The ID of the notification to mark as read.
 * @returns {Promise} A promise that resolves when the operation is successful.
 */
export const markNotificationAsRead = (notificationId) => taskiApi.post(`/v1/notifications/${notificationId}/set-as-read`);


/**
 * deleteNotification
 *
 * Marks a specific notification as deleted for the logged-in user.
 *
 * @param {number|string} notificationId - The ID of the notification to mark as deleted.
 * @returns {Promise} A promise that resolves with the response from the API when the operation is successful.
 */
export const deleteNotification = (notificationId) => taskiApi.post(`/v1/notifications/${notificationId}/set-as-delete`);


/**
 * fetchUnreadChats
 *
 * Fetches all chats that have unread messages for the logged-in user.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of chat objects with unread messages.
 */
export const fetchUnreadChats = () => taskiApi.get('/v1/unread-chats');


/**
 * sendEmailVerificationCode
 *
 * Sends a verification code to the registered email address of the logged-in user.
 *
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const sendEmailVerificationCode = () => taskiApi.post('/v1/email-verification-code');


/**
 * validateEmailVerificationCode
 *
 * Validates the verification code received via email.
 *
 * @param {string} verificationCode - The verification code received by the user.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const validateEmailVerificationCode = (code) => taskiApi.post('/v1/email-verification-process', { verification_code: code });


/**
 * sendSMSVerificationCode
 *
 * Sends a verification code via SMS to the phone number saved in the user's profile.
 *
 * @param {string} phone - The phone number to which the verification code should be sent.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const sendSMSVerificationCode = (phone) => taskiApi.post('/v1/sms-verification-code', { phone });


/**
 * validateSMSVerificationCode
 *
 * Validates the verification code received via SMS.
 *
 * @param {string} verificationCode - The verification code received by the user via SMS.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const validateSMSVerificationCode = (code) => taskiApi.post('/v1/sms-verification-process', { verification_code: code });


/**
 * inizializateChat
 *
 * Initializes a new chat with the specified participants and service ID.
 *
 * @param {string} userName - The title of the chat, usually the name of the user.
 * @param {Array<number>} userIds - An array of user IDs that will participate in the chat.
 * @param {number|string} serviceId - The ID of the service related to the chat.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const inizializateChat = (userName, userIds, serviceId) => taskiApi.post('/v1/chats', {
  title: userName,
  participants: userIds,
  service_id: serviceId
});


/**
 * fetchUserProfile
 *
 * Retrieves the profile information of the logged-in user.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing the user's profile data.
 */
export const fetchUserProfile = () => taskiApi.get('/v1/user/get_profile');


/**
 * deleteServiceFile
 *
 * Deletes a file associated with a specific service.
 *
 * @param {number|string} serviceId - The ID of the service from which the file should be deleted.
 * @param {number|string} fileId - The ID of the file to delete.
 * @returns {Promise} A promise that resolves with the response from the API when the operation is successful.
 */
export const deleteServiceFile = (serviceId, fileId) => taskiApi.delete(`/v1/services/${serviceId}/delete-file/${fileId}`);


/**
 * fetchCategories
 *
 * Retrieves all available categories.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of category objects.
 */
export const fetchCategories = () => taskiApi.get('/v1/categories');


/**
 * fetchRateTypes
 *
 * Retrieves all available rate types.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of rate type objects.
 */
export const fetchRateTypes = () => taskiApi.get('/v1/rate_types');


/**
 * saveService
 *
 * Saves or updates a service based on whether the service ID is provided.
 * If `data.id` exists, the service with that ID will be updated; otherwise, a new service will be created.
 *
 * @param {Object} formData - The form data containing the service details to be sent to the server.
 * @param {Object} [data] - Optional data object that may contain an `id` property for updating an existing service.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const saveService = (formData, data) => {
  return taskiApi({
    method: 'post',
    url: data && data.id ? `/v1/services/${data.id}` : '/v1/services',
    data: formData,
    headers: {},
  });
};


/**
 * requestService
 *
 * Submits a request for a service using the provided form data.
 *
 * @param {Object} formData - The form data containing details of the service request.
 * @returns {Promise} A promise that resolves with the response from the API when the request is successful.
 */
export const requestService = (formData) => taskiApi.post('/v1/service/request', formData);


/**
 * fetchServiceRequest
 *
 * Fetches all service requests related to the services of the logged-in user.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of service request objects.
 */
export const fetchServiceRequest = () => taskiApi.get('/v1/service/request');


/**
 * updateServiceRequest
 *
 * Updates a specific service request with the provided data.
 *
 * @param {number|string} serviceRequestId - The ID of the service request to update.
 * @param {Object} formData - The data to update the service request with.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const updateServiceRequest = (serviceRequestId, formData) => taskiApi.post(`/v1/service/request/${serviceRequestId}`, formData);


/**
 * verifiedUniqueUserField
 *
 * Verified if a field is unique for the logged-in user.
 *
 * @param {string} field - The name of the field to verify (e.g., "email", "username").
 * @param {string} value - The value to verify
 * @returns {Promise} A promise that resolves with the response from the API when the field is successfully updated.
 */
export const verifiedUniqueUserField = (field, value) => taskiApi.post('/v1/user/validate-unique-field', { field, value });


/**
 * updateUserField
 *
 * Updates a specific field in the user's profile. This function typically checks the uniqueness of the field's value.
 *
 * @param {string} field - The name of the field to update (e.g., "email", "username").
 * @param {string} value - The new value to set for the specified field.
 * @returns {Promise} A promise that resolves with the response from the API when the field is successfully updated.
 */
export const updateUserField = (field, value) => taskiApi.post('/v1/user/update_profile', { field, value });


/**
 * sendEmailCodeToUpdate
 *
 * Sends a verification code to the provided email address for the purpose of updating the user's profile email.
 *
 * @param {string} email - The email address to which the verification code should be sent.
 * @returns {Promise} A promise that resolves with the response from the API when the code is successfully sent.
 */
export const sendEmailCodeToUpdate = (email) => taskiApi.post('/v1/email-verification-to-update', { email });


/**
 * sendSMSCodeToUpdate
 *
 * Sends a verification code via SMS to the provided phone number for the purpose of updating the user's profile phone number.
 *
 * @param {string} phone - The phone number to which the verification code should be sent.
 * @returns {Promise} A promise that resolves with the response from the API when the code is successfully sent.
 */
export const sendSMSCodeToUpdate = (phone) => taskiApi.post('/v1/sms-verification-to-update', { phone });


/**
 * doSearch
 *
 * Performs a search based on the provided parameters. The search can include a keyword, location coordinates (latitude and longitude),
 * and pagination options.
 *
 * @param {Object} params - The search parameters.
 * @param {string} params.keyword - The keyword to search for.
 * @param {number} params.lat - The latitude coordinate for the search location.
 * @param {number} params.long - The longitude coordinate for the search location.
 * @param {number} [params.page] - (Optional) The page number for pagination.
 * @returns {Promise} A promise that resolves with the search results from the API.
 */
export const doSearch = (params) => taskiApi.get('/v1/search', { params });


/**
 * fetchCountries
 *
 * Retrieves a list of all available countries.
 *
 * @param {string} value - A query parameter to filter the list of countries (optional).
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of country objects.
 */
export const fetchCountries = (value) => taskiApi.get('/v1/countries');


/**
 * fetchStatesByCountry
 *
 * Retrieves a list of states/provinces based on the selected country.
 *
 * @param {string} country - The name or code of the country for which to retrieve states/provinces.
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of state objects.
 */
export const fetchStatesByCountry = (country) => taskiApi.get(`/v1/states?country=${country}`);


/**
 * fetchCitiesByState
 *
 * Retrieves a list of cities based on the selected state/province.
 *
 * @param {string} state - The name or code of the state/province for which to retrieve cities.
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of city objects.
 */
export const fetchCitiesByState = (state) => taskiApi.get(`/v1/cities?state=${state}`);


/**
 * fetchRatings
 *
 * Fetches the ratings for a specific user based on their user ID.
 *
 * @param {number|string} userId - The ID of the user for whom the ratings should be fetched.
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of rating objects.
 */
export const fetchRatings = (userId) => taskiApi.get(`/v1/ratings?user_id=${userId}`);


/**
 * createRatings
 *
 * Creates a new rating for a service or user with the provided data.
 *
 * @param {Object} formData - The data for the rating to be created.
 * @returns {Promise} A promise that resolves with the response from the API.
 */
export const createRatings = (formData) => taskiApi.post(`/v1/ratings`, formData);


/**
 * fetchServices
 *
 * Retrieves all services associated with the logged-in user.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing an array of service objects.
 */
export const fetchServices = () => taskiApi.get('/v1/services');


/**
 * deleteService
 *
 * Deletes a specific service associated with the logged-in user.
 *
 * @param {number|string} serviceId - The ID of the service to delete.
 * @returns {Promise} A promise that resolves with the response from the API when the operation is successful.
 */
export const deleteService = (serviceId) => taskiApi.delete(`/v1/services/${serviceId}`);


/**
 * fetchUserSystemPreferences
 *
 * Retrieves the system preferences for the logged-in user.
 *
 * @returns {Promise} A promise that resolves with the response from the API, containing the user's system preferences.
 */
export const fetchUserSystemPreferences = () => taskiApi.get('/v1/user/system-preferences');


/**
 * updateUserSystemPreferences
 *
 * Updates the system preferences for the logged-in user.
 *
 * @param {Object} data - An object containing the system preferences to update. This can include:
 *    - {boolean} allow_email_notifications
 *    - {boolean} allow_sms_notifications
 *    - {boolean} enable_chat_messages
 *    - {boolean} enable_service_visibility
 *    - {string} currency
 *    - {string} timezone
 *    - {boolean} allow_marketing_emails
 *    - {boolean} enable_two_factor_auth
 *    - {string} default_view
 *    - {boolean} enable_notification_sound
 *    - {number} auto_logout_duration (in minutes)
 * @returns {Promise} A promise that resolves with the response from the API when the preferences are successfully updated.
 */
export const updateUserSystemPreferences = (data) => taskiApi.post('/v1/user/system-preferences', data);


/**
 * fetchLegalDocument
 *
 * This function fetches a legal document from the API based on the provided document type and language.
 * It retrieves the latest version of the requested document. If the document is not available in the specified language,
 * the API will attempt to return it in English ('en') or Spanish ('es'), and if unavailable, it will return the first found version
 * in any language, optionally translating it.
 *
 * @param {string} documentType - The type of the legal document to fetch. Expected values could be 'terms', 'privacy_policy', etc.
 * @param {string} language - The language code for the document (e.g., 'en' for English, 'es' for Spanish).
 * @returns {Promise<Object>} A promise that resolves with the API response containing the legal document.
 * The response will include the document type, language, version, and content.
 *
 * @throws {Error} If the API request fails or the document is not found in any language.
 */
export const fetchLegalDocument = (docuemntType, language) => taskiApi.get(`/v1/legal-documents?document_type=${docuemntType}&language_code=${language}`);


/**
 * passwordRecovery
 *
 * Initiates the password recovery process by sending a recovery email to the provided email address.
 *
 * @param {Object} formData - An object containing the user's email address for password recovery.
 * @returns {Promise} A promise that resolves with the response from the API upon successful initiation of the recovery process.
 */
export const passwordRecovery = (formData) => taskiApi.post('/v1/password-recovery', formData);


/**
 * resetPassword
 *
 * Resets the user's password using the provided email, token, and new password.
 *
 * @param {Object} formData - An object containing the email, token, new password, and password confirmation.
 * @returns {Promise} A promise that resolves with the response from the API upon successful password reset.
 */
export const resetPassword = (formData) => taskiApi.post('/v1/reset-password', formData);




