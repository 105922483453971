import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/App.css';

const Footer = () => {

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between align-items-center">
          {/* Logo and Slogan */}
          <Col xs={12} md={6} className="mb-3 mb-md-0 text-center text-md-start">
            <Link to={`/${lng}`} className="footer-logo">
              <h5>{t('App.Title')}</h5>
            </Link>
            <p className="footer-text">{t('Footer.Slogan')}</p>
          </Col>

          {/* Links to Documents */}
          <Col xs={12} md={6} className="text-center text-md-end">
            <ul className="list-unstyled footer-links d-inline-flex flex-column flex-md-row">
              <li className="me-md-4 mb-2 mb-md-0">
                <Link to={`/${lng}/terms-and-conditions`}>{t('Footer.TermsAndConditions')}</Link>
              </li>
              <li className="me-md-4 mb-2 mb-md-0">
                <Link to={`/${lng}/privacy-policy`}>{t('Footer.PrivacyPolicy')}</Link>
              </li>
              <li>
                <Link to={`/${lng}/cookie-policy`}>{t('Footer.CookiePolicy')}</Link>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Copyright Section */}
        <Row className="mt-3">
          <Col xs={12} className="text-center">
            <p className="copyright-text">{t('Footer.Copyright')}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;