import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CustomConfirmation = ({ show, onHide, onConfirm, title, message, placeholder, inputLabel, inputValue, setInputValue, variant = 'warning', showTextArea = false }) => {

  const { t } = useTranslation();
  
  const handleConfirm = () => {
    onConfirm(inputValue);
    onHide();
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
        {showTextArea && (
          <Form.Group controlId="formBasicTextarea">
            <Form.Label className="fw-bold" >{inputLabel}:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={inputValue}
              onChange={handleChange}
              placeholder={placeholder}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('CustomConfirmation.Cancel')}
        </Button>
        <Button variant={variant} onClick={handleConfirm}>
          {t('CustomConfirmation.Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomConfirmation;