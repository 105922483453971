import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Row, Col, Container, InputGroup, FormControl, Button, ListGroup, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'; // Importa OverlayTrigger y Tooltip
import { fetchChats, fetchMessages, markMessagesAsRead } from '../services/TaskiMapApiService';
import { useWebSocket } from '../contexts/WebSocketContext'; 
import { formatDistanceToNow, parseISO } from 'date-fns';
import LoadingScreen from '../components/LoadingScreen';
import { BiCheck, BiCheckDouble } from 'react-icons/bi';
import { useSound } from '../contexts/SoundContext';
import { useUser } from '../contexts/UserContext'; 
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { es, enUS } from 'date-fns/locale';
import useAuth from '../hooks/useAuth';
import '../styles/App.css';

const ChatList = ({ chats, onSelectChat, currentUser, newMessages }) => {

  const { t } = useTranslation();

  return (
    <div>
      <div className="chat-list-header">
        <h5 className="chat-list-title">{t('Messenger.Chats')}</h5>
        <hr className="chat-list-divider" />
      </div>
      <ListGroup variant="flush" className="chat-list"> 
        {chats.map((chat) => {
          const otherParticipant = chat.participants.find(participant => participant.id !== currentUser.id);
          // const serviceName = chat.services && chat.services.length > 0 ? chat.services[0].name : null;
          const hasNewMessages = newMessages[chat.id] || chat.has_unread_messages;

          return (
            <ListGroup.Item 
              key={chat.id} 
              action 
              onClick={() => onSelectChat(chat)} 
              className="d-flex align-items-center chat-list-item"
            >
              <div className="d-flex align-items-center chat-list-item-container">
                <Image
                  src={otherParticipant?.data_user.img_avatar || 'https://via.placeholder.com/40'}
                  roundedCircle
                  className="chat-avatar me-3"
                  style={{ width: '48px', height: '48px', objectFit: 'cover', border: '2px solid #e0e0e0' }}
                />
                <div className="chat-info flex-grow-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <strong className="chat-name">{otherParticipant ? otherParticipant.name : t('Messenger.UnknownUser') }</strong>
                    {hasNewMessages && (
                      <span style={{ 
                        backgroundColor: 'red', 
                        borderRadius: '50%', 
                        width: '10px', 
                        height: '10px', 
                        display: 'inline-block',
                        marginLeft: '10px',
                        animation: 'jump 1s ease-in-out infinite'
                      }}></span>
                    )}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};


const ChatWindow = ({ messages, user, typingUser, selectedService }) => {

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];
  const messagesEndRef = useRef(null);
  const localeMap = {
    es: es,
    en: enUS,
    pt: 'pt-BR',
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatTimeAgo = (dateString, lng) => {
    const date = parseISO(dateString);
    const locale = localeMap[lng];
    return formatDistanceToNow(date, { addSuffix: true, locale: locale });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-window d-flex flex-column p-3">

      {selectedService && (
        <div className="chat-window-header d-flex align-items-center mb-3 bg-light py-2">
          <Image
            src={selectedService.image || 'https://via.placeholder.com/40'}
            className="me-3"
            style={{ width: '48px', height: '48px', objectFit: 'cover' }}
          />
          <strong>{selectedService.name}</strong>
        </div>
      )}

      <div className="chat-window-body flex-grow-1 overflow-auto">
        <ListGroup>
          {messages.map((message) => (
            <ListGroup.Item 
              key={message.id} 
              className={(message.user_id === user.id) ? 'my-message' : 'other-message'}
            >
              <Row className="align-items-center">
                {!(message.user_id === user.id) && (
                  <Col xs="auto">
                    <Image
                      src={message.user.data_user.img_avatar || 'https://via.placeholder.com/40'}
                      roundedCircle
                      className="avatar"
                      style={{ border: 'solid 0.5px #C0C0C0' }}
                    />
                  </Col>
                )}
                <Col>
                  <div className="message-content">
                    <div className="message-header">
                      {!(message.user_id === user.id) && (
                        <strong>{message.user.data_user.first_name} {message.user.data_user.last_name}</strong>
                      )}
                      {(message.user_id === user.id) && (
                        <strong>{t('Messenger.You')}</strong>
                      )}
                    </div>
                    <div className="message-text">{message.message}</div>
                    <div className="message-time"
                      style={message.user_id === user.id ? {} : { right: 0 }}
                    >
                      <small>{formatTimeAgo(message.created_at, lng)}</small>

                      {(message.user_id === user.id) && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-read-${message.id}`}>
                            <small>{message.read_at ? `${t('Messenger.Seen')} ${formatTimeAgo(message.read_at, lng)}` : t('Messenger.NotSeenYet') }</small>
                          </Tooltip>
                        }
                      >
                        <span>
                          {message.read_at ? <BiCheckDouble className="text-success ms-2" /> : <BiCheck className="text-muted ms-2" />}
                        </span>
                      </OverlayTrigger>
                      )}

                    </div>
                  </div>
                </Col>
                {(message.user_id === user.id) && (
                  <Col xs="auto">
                    <Image
                      src={message.user.data_user.img_avatar || 'https://via.placeholder.com/40'}
                      roundedCircle
                      className="avatar"
                      style={{ border: 'solid 0.5px #C0C0C0' }}
                    />
                  </Col>
                )}
              </Row>
            </ListGroup.Item>
          ))}

          {typingUser && (
            <ListGroup.Item 
              style={{
                padding: '0px',
                fontSize: '0.7rem',
                display: 'block',
                right: '0px',
                top: '-10px',
                textAlign: 'center',
                border: 'none'
              }}
            >
              <div className="typing-indicator">
                <span className="typing-indicator-text">{typingUser} {t('Messenger.IsTyping')}</span>
                <span className="typing-indicator-dots">
                  <span className="typing-indicator-dot"></span>
                  <span className="typing-indicator-dot"></span>
                  <span className="typing-indicator-dot"></span>
                </span>
              </div>
            </ListGroup.Item>
          )}

          <div ref={messagesEndRef} />

        </ListGroup>
      </div>
    </div>
  );
};


const MessageInput = ({ onSendMessage, handleSetTyping, selectedChatId, setMessagesAsRead }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [keyPressCounter, setKeyPressCounter] = useState(0);

  const handleInputChange = (e) => {
    setMessage(e.target.value);

    // Resetear el contador si el mensaje se vacía
    if (e.target.value === '') {
      setKeyPressCounter(0);
    }

    if (e.nativeEvent.inputType !== 'deleteContentBackward' && e.nativeEvent.inputType !== 'deleteContentForward') {
      handleSetTyping(e);
    }

    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
      setKeyPressCounter(0); // Resetear el contador al enviar un mensaje
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Evita que se agregue un salto de línea
      setMessagesAsRead(selectedChatId);
      handleSendMessage();
    }
  };

  const handleFocus = () => {
    if (!isFocused && selectedChatId) {
      setMessagesAsRead(selectedChatId);
      setIsFocused(true);
    }
  };

  const handleKeyDown = () => {
    if (keyPressCounter === 0 && selectedChatId) {
      setMessagesAsRead(selectedChatId);
    }
    setKeyPressCounter((prevCount) => prevCount + 1);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <InputGroup className="message-input" style={{ marginTop: '2px' }}>
      <FormControl
        as="textarea"
        placeholder={selectedChatId ? t('Messenger.TypeAMessage') : t('Messenger.SelectAChatToStartAConversation') }
        value={message}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        style={{ resize: 'none', overflow: 'hidden' }}
        disabled={!selectedChatId}
        onBlur={handleBlur}
      />
      <Button variant="primary" onClick={handleSendMessage} disabled={!selectedChatId}>
        {t('Messenger.Send')}
      </Button>
    </InputGroup>
  );
};


function Messenger() { 
 
  const { user, validateSession } = useUser();
  const ws = useWebSocket();

  const { playNewMessageSound, playNewChatSound, playNewMessageChatSound } = useSound();
  const [chatSelectedFromQuery, setChatSelectedFromQuery] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [userValidated, setUserValidated] = useState(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [typingUser, setTypingUser] = useState(null);
  const [newMessages, setNewMessages] = useState({});
  const selectedChatIdRef = useRef(selectedChatId);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const { isLoading } = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chatIdFromQuery = searchParams.get('id');
  
  const setMessagesAsRead = useCallback((chatId) => {
    // Llamada al API para marcar los mensajes como leídos
    markMessagesAsRead(chatId)
      .then((response) => {
        setNewMessages(prevState => ({
          ...prevState,
          [chatId]: false
        }));
        setChats(prevChats =>
          prevChats.map(c =>
            c.id === chatId ? { ...c, has_unread_messages: false } : c
          )
        );

        if (ws && ws.readyState === WebSocket.OPEN && response.data.data.messages) {
          //emitir el evento 'mark_as_read' para todos los usuarios
          ws.send(JSON.stringify({ type: 'mark_as_read', data: response.data.data.messages, chatId: chatId }));
        }
        
      })
      .catch(error => {
        console.error('Error al marcar mensajes como leídos:', error);
      });
  }, [ws]);


  const handleSelectChat = useCallback((chat) => {
    setSelectedChatId(chat.id);
    selectedChatIdRef.current = chat.id;

    // Extraer el servicio y la imagen asociada si existe
    if (chat.services && chat.services.length > 0) {
      const service = chat.services[0];
      const serviceImage = service.files && service.files.length > 0 ? service.files[0].url : null;
      setSelectedService({
        name: service.name,
        image: serviceImage
      });
    } else {
      setSelectedService(null);
    }
    
    // Unirse a un chat especifico en el WS para no hacer broadcast de los mesajes a todos
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: 'join_chat', chatId: chat.id, userId: user.id }));
    }

    setMessagesAsRead(chat.id);
  }, [ws, user, setMessagesAsRead]);


  useEffect(() => {
    if (userValidated) {
      fetchChats()
        .then(response => setChats(response.data.data))
        .catch(error => console.error('Error fetching chats:', error));
    }
  }, [userValidated]);


  const handleSendMessage = (message) => {
    const data = JSON.stringify({ type: 'new_message', text: message, chatId: selectedChatId, userId: user.id });
    // ws.current.send(data);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(data);
    }
  };


  useEffect(() => {
    if (selectedChatId) {
      fetchMessages(selectedChatId)
        .then(response => setMessages(response.data.data))
        .catch(error => console.error('Error fetching messages:', error));
    }
  }, [selectedChatId]);


  useEffect(() => {
    if (chatIdFromQuery && chats.length > 0 && !chatSelectedFromQuery) {
      const chatToSelect = chats.find(chat => chat.id === parseInt(chatIdFromQuery, 10));
      if (chatToSelect) {
        handleSelectChat(chatToSelect);
        setChatSelectedFromQuery(true);
      }
    }
  }, [chatIdFromQuery, chats, chatSelectedFromQuery, handleSelectChat]);


  const handleSetTyping = (e) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: 'typing', userId: user.id, userName: user.name, chatId: selectedChatId }));
    }
  };


  useEffect(() => {
    const timeout = setTimeout(() => setTypingUser(null), 2000);
    return () => clearTimeout(timeout);
  }, [typingUser]);


  useEffect(() => {
    const handleMessage = event => {
      const data = JSON.parse(event.data);
      
      switch (data.type) {
        case 'new_message':
          if (data.chat_id === selectedChatIdRef.current) {
            setMessages(messages => [...messages, data]);
            if (data.user_id !== user.id) {
              playNewMessageSound();
              setTypingUser(null);
            }
          } else {
            // Marcar el chat como con nuevo mensaje
            playNewMessageChatSound();
            setNewMessages(prevState => ({
              ...prevState,
              [data.chat_id]: true
            }));
          }
          break;
        
        case 'new_chat':
          if (data.data.chat.participants.some(participant => participant.id === user.id)) {
            setChats(chats => [...chats, data.data.chat]);
            playNewChatSound();
          }
          break;
        
        case 'typing':
          if (data.chatId === selectedChatIdRef.current && data.userId !== user.id) {
            setTypingUser(data.userId !== user.id ? data.userName : null);
          }
          break;

        case 'read_messages':
          const updatedMessages = data.messages.data;
          if (updatedMessages.length > 0) {
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                updatedMessages.some((updatedMsg) => updatedMsg.id === msg.id)
                  ? { ...msg, read_at: updatedMessages.find((updatedMsg) => updatedMsg.id === msg.id).read_at }
                  : msg
              )
            );
          }
          break;

        case 'join_chat':
          // tal vez emitir un sonido para indicar que un usuario se unió al chat

          break;

        default:
          console.warn('Unhandled message type:', data.type);
          break;
      }
    };

    // Agregar el manejador de eventos solo si el WebSocket está abierto
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.addEventListener('message', handleMessage);
    }

    // Retirar el manejador cuando el componente se desmonte o el WebSocket cambie
    return () => {
      if (ws) {
        ws.removeEventListener('message', handleMessage);
      }
    };
  }, [ws, selectedChatId, user, playNewMessageSound, playNewChatSound, playNewMessageChatSound]);


  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, [validateSession]);

  useEffect(() => {
    if (ws) {
      const handleOpen = () => setWsConnected(true);

      if (ws.readyState === WebSocket.OPEN) {
        handleOpen();
      } else {
        ws.addEventListener('open', handleOpen);
      }

      return () => {
        if (ws) {
          ws.removeEventListener('open', handleOpen);
        }
      };
    }
  }, [ws]);


  if (isLoading || !wsConnected || !userValidated) {
    return <LoadingScreen />;
  }

  return (
    <div className="d-flex">
      <Sidebar />
      <Container fluid className="m-3 flex-grow-1">
        <Row>
          <Col md={4} className="no-padding-important ">
            <ChatList chats={chats} onSelectChat={handleSelectChat} currentUser={user} newMessages={newMessages} />
          </Col>
          <Col md={8} className="no-padding-important ">
            <ChatWindow messages={messages} user={user} typingUser={typingUser} selectedService={selectedService} />
            <MessageInput 
              onSendMessage={handleSendMessage}
              handleSetTyping={handleSetTyping}
              selectedChatId={selectedChatId} 
              setMessagesAsRead={setMessagesAsRead} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Messenger;

