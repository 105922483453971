import React, { useRef, useEffect } from 'react';

import { InputGroup, Form } from 'react-bootstrap';
import '../styles/App.css';

const CustomField = ({ label, labelHtml, name, type = "text", editable, placeholder, autoComplete = "off", value = '', onChange }) => {
  
  const inputRef = useRef(null);

  useEffect(() => {
    if (editable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editable]);

  return (
    <>
      <InputGroup className="mt-1" style={{ height: '25px' }}>
        <Form.Control 
          name={name}
          ref={inputRef}
          type={type}
          required
          readOnly={!editable}
          style={{ border: 'none', height: '25px', borderRadius: '5px' }}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className="custom-input"
          onChange={onChange}
        />
      </InputGroup>

      {label && (
        <Form.Text style={{ marginLeft: '11px', fontSize: '0.8rem', color: 'lightgray', top: '-3px', position: 'relative' }}>
          {labelHtml ? <span dangerouslySetInnerHTML={{ __html: labelHtml }} /> : label}
        </Form.Text>
      )}
    </>
  );
}

export default CustomField;