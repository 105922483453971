import React, { useState } from 'react';
import { passwordRecovery } from '../services/TaskiMapApiService';
import { Button, Form, Col, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/App.css';

function PasswordRecovery() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const navigate = useNavigate();

  // Función para validar el correo electrónico
  const validateEmail = (email) => {
    // Expresión regular simple para validar formato de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Manejar el cambio del input de correo electrónico
  const handleChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsEmailValid(validateEmail(inputEmail));
  };

  // Manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await passwordRecovery({ email });
      setSuccessMessage(t('PasswordRecovery.SuccessMessage'));
      setEmail(''); // Reiniciar el campo de email
    } catch (err) {
      setError(err.response?.data?.message || t('Errors.recoveryFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vh-100 d-flex flex-column justify-content-between">
      <Header showTitle={true} showRegisterButton={false} />
      
      <div className="password-recovery-container">
        <Col md={6} className="mx-auto">
          <Form onSubmit={handleSubmit} className="password-recovery-form shadow-lg p-4 rounded">
            <h1 className="h3 mb-3 text-center">{t('PasswordRecovery.Title')}</h1>

            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form.Group className="mb-3">
              <Form.Label>{t('PasswordRecovery.Email')}</Form.Label>
              <Form.Control 
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder={t('PasswordRecovery.EmailPlaceholder')}
                required
                isInvalid={email && !isEmailValid} // Mostrar error visual si no es válido
              />
              <Form.Control.Feedback type="invalid">
                {t('PasswordRecovery.InvalidEmail')}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-between mt-4">
              <Button variant="link" onClick={() => navigate('/')}>
                {t('PasswordRecovery.Cancel')}
              </Button>

              <Button 
                variant="primary" 
                type="submit" 
                disabled={!isEmailValid || loading} // Deshabilitar si el correo no es válido o está cargando
                className="btn-lg"
              >
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('PasswordRecovery.Submit')}
              </Button>
            </div>
          </Form>
        </Col>
      </div>

      <Footer />
    </div>
  );
}

export default PasswordRecovery;