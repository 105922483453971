import { useEffect, useState } from 'react';

import { validateUserSession } from '../services/TaskiMapApiService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function useAuth() {
  const { t } = useTranslation();
  const [userType, setUserType] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const validateSession = async () => {
      try {
        const response = await validateUserSession();
        if (response.data.status && response.data.data && response.data.data.user) {
          setUserType(response.data.data.user.data_user.user_type);
        } else {
          throw new Error(t('useAuth.Error'));
        }
      } catch (error) {
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    validateSession();
  }, [navigate, t]);

  return { userType, isLoading };
}

export default useAuth;