import React from 'react';

import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Image } from 'react-bootstrap';
import StarRating from './StarRating';

const DisplayRating = ({ ratings }) => {
  const { t } = useTranslation();

  if (!ratings.length) return null;

  return (
    <div className="mt-3">
      <h5>{t('DisplayRating.Reviews')}:</h5>
      {ratings.map(rating => (
        <div key={rating.id} className="d-flex align-items-center mb-3 p-3" style={{ background: '#f8f9fa', border: '1px solid #ddd', borderRadius: '5px', position: 'relative' }}>
          <div className="flex-shrink-0">
            <Image src={rating.rater.data_user.img_avatar || 'https://dummyimage.com/60x60/ced4da/6c757d.jpg'} roundedCircle style={{ width: '60px', height: '60px' }} />
          </div>
          <div className="flex-grow-1 ms-3">
            <h6 className="mb-0">{rating.rater.name}</h6>
            
            <StarRating value={rating.rating} />

            <p className="text-muted mt-2">{rating.review || t('DisplayRating.NoReview')}</p>
          </div>
          <div className="ms-auto text-muted" style={{ position: 'absolute', bottom: '10px', right: '10px', fontSize: '0.7rem' }}>
            {t('DisplayRating.Rated')}: {format(parseISO(rating.created_at), 'MMM dd, yyyy')}
            <br />
            {rating.is_provider_rating ? t('DisplayRating.ByServiceProvider') : t('DisplayRating.ByClient')}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DisplayRating;