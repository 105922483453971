import React, { createContext, useContext, useState } from 'react';

const SoundContext = createContext();

export const SoundProvider = ({ children }) => {
  const [audioContext, setAudioContext] = useState(null);

  const createOscillator = (frequency, startTime, duration) => {
    let context = audioContext;

    if (!context) {
      context = new (window.AudioContext || window.webkitAudioContext)();
      setAudioContext(context);
    }

    if (context.state === 'suspended') {
      context.resume();
    }

    const oscillator = context.createOscillator();
    const gainNode = context.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(context.destination);

    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(frequency, context.currentTime + startTime);

    gainNode.gain.setValueAtTime(0.5, context.currentTime + startTime);
    gainNode.gain.exponentialRampToValueAtTime(0.001, context.currentTime + startTime + duration);

    oscillator.start(context.currentTime + startTime);
    oscillator.stop(context.currentTime + startTime + duration);
  };

  const playNewMessageSound = () => {
    createOscillator(880, 0, 0.1);
    createOscillator(1046, 0.15, 0.1);
  };

  const playNewChatSound = () => {
    createOscillator(600, 0, 0.2);
    createOscillator(800, 0.25, 0.2);
    createOscillator(700, 0.5, 0.2);
  };

  const playNewMessageChatSound = () => {
    createOscillator(660, 0, 0.2);
  };

  return (
    <SoundContext.Provider value={{ playNewMessageSound, playNewChatSound, playNewMessageChatSound }}>
      {children}
    </SoundContext.Provider>
  );
};

export const useSound = () => useContext(SoundContext);

export default SoundProvider; // Asegúrate de que esto esté presente si estás utilizando exportación por defecto