import React from 'react';

import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ServicesCard = () => {

  const { t } = useTranslation();
  
  const services = [];

  if (services.length === 0) {
    return null;
  }

  return (
    <div className="container">
      <h5 className="text-center mb-3">{t('Services.Title')}</h5>
      <div className="row">
        {services.map(service => (
          <div key={service.id} className="col-md-4 mb-3">
            <Card>
              <Card.Body>
                <Card.Title className="text-center">{service.title}</Card.Title>
                <Card.Text className="text-center">{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesCard;