import React, { useState } from 'react';

const Star = ({ selected = false, onSelect = () => {}, onHover = () => {}, size = 20, editable }) => (
  <span
    style={{ cursor: editable ? 'pointer' : 'default', color: selected ? '#ffd700' : 'gray', fontSize: `${size}px` }}
    onClick={editable ? onSelect : undefined}
    onMouseEnter={editable ? onHover : undefined}
  >
    ★
  </span>
);

const StarRating = ({ value = 0, totalStars = 5, onChange, size = 20, editable = false }) => {
  const [hover, setHover] = useState(undefined); // Estado para manejar el hover

  const handleClick = (index) => {
    if (editable) {
      onChange(index + 1);
    }
  };

  const handleMouseEnter = (index) => {
    if (editable) {
      setHover(index);
    }
  };

  const handleMouseLeave = () => {
    if (editable) {
      setHover(undefined);
    }
  };

  return (
    <div style={{ display: 'inline-block' }} onMouseLeave={handleMouseLeave}>
      {[...Array(totalStars)].map((_, i) => (
        <Star
          key={i}
          selected={hover !== undefined ? i <= hover : i < value}
          onSelect={() => handleClick(i)}
          onHover={() => handleMouseEnter(i)}
          size={size}
          editable={editable}
        />
      ))}
    </div>
  );
};

export default StarRating;