import React, { useState, useEffect, useRef } from 'react';

import { sendMessage, inizializateChat } from '../services/TaskiMapApiService';
import { useWebSocket } from '../contexts/WebSocketContext';
import { ListGroup, Pagination } from 'react-bootstrap';
import ServiceRequestModal from './ServiceRequestModal';
import RegisterClientModal from './RegisterClientModal';
import SendMessageModal from './SendMessageModal';
import { useUser } from '../contexts/UserContext';
import SearchResultItem from './SearchResultItem';
import { useTranslation } from 'react-i18next';
import RequiredLogin from './RequiredLogin';
import ModalMessage from './ModalMessage';
import LoginModal from './LoginModal';

const SearchResults = ({ 
  results, 
  onPageChange,
  showRequiredLoginModal, 
  setShowRequiredLoginModal,
  selectedService,
  setSelectedService,
  latitude,
  longitude
}) => {

  const { t } = useTranslation();
  const { user, validateSession } = useUser();
  const userRef = useRef(user);
  const ws = useWebSocket();

  const [selectedServiceForMessage, setSelectedServiceForMessage] = useState(null);
  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [showApiMessageModal, setShowApiMessageModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [messageComplement, setMessageComplement] = useState(false);
  const [requestedServices, setRequestedServices] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [apiMessageType, setApiMessageType] = useState('');
  const [apiMessage, setApiMessage] = useState('');


  useEffect(() => {
    userRef.current = user;
  }, [user]);

  // Execute pending action if user is logged in and pendingAction is not null
  useEffect(() => {
    if (user && pendingAction) {
      pendingAction();
      setPendingAction(null); 
    }
  }, [user, pendingAction]);

  const handleOpenRegisterModal = () => {
    setShowRequiredLoginModal(false);
    setShowRegisterModal(true);
  };

  const handleOpenLoginModal = () => {
    setShowRequiredLoginModal(false); 
    setShowLoginModal(true);
  }

  const handleCloseServiceRequestModal = () => {
    setSelectedService(null);
    setShowServiceRequestModal(false);
  }

  const handlePageClick = (page, latitude, longitude) => () => {
    onPageChange(page, latitude, longitude);
  };

  const handleRequestService = (service) => {
    if (userRef.current) {
      setSelectedService(service);
      setShowServiceRequestModal(true);
    } else {
      setMessageComplement(t('RequiredLogin.RequestAService'));
      setPendingAction(() => () => handleRequestService(service));
      setShowRequiredLoginModal(true);
    }
  }

  const handleRequestQuote = (service) => {
    if (userRef.current) {
      setSelectedService(service);
      console.log('logic to request quote');
    } else {
      setMessageComplement(t('RequiredLogin.RequestAQuote'));
      setPendingAction(() => () => handleRequestQuote(service));
      setShowRequiredLoginModal(true);
    }
  }

  const handleOpenSendMessageModal = (service) => {
      setSelectedServiceForMessage(service);
      setShowSendMessageModal(true);
  };

  const handleSendMessage = async (message) => {
    if (!userRef.current) {
      setMessageComplement(t('RequiredLogin.SendMessage'));
      setPendingAction(() => () => handleSendMessage(message));
      setShowRequiredLoginModal(true);
      return;
    }

    try {
      let chatId = selectedServiceForMessage.chats && selectedServiceForMessage.chats.length > 0 ? selectedServiceForMessage.chats[0].id : null;

      // Si no existe chatId, intentamos crear un nuevo chat
      if (!chatId) {

        let userName = (userRef.current.data_user.first_name && userRef.current.data_user.last_name)
          ? `${userRef.current.data_user.first_name} ${userRef.current.data_user.last_name}`
          : userRef.current.name || userRef.current.id;

        const chatResponse = await inizializateChat(
          userName,
          [userRef.current.id, selectedServiceForMessage.user.id],
          selectedServiceForMessage.id
        );

        chatId = chatResponse.data.data.id;

        ws.send(JSON.stringify({
          type: 'new_chat',
          chat: chatResponse.data.data
        }));
      }

      // Enviar el mensaje al chat correspondiente
      await sendMessage(chatId, message);

      setShowSendMessageModal(false);
      setApiMessage('Message sent successfully.');
      setApiMessageType('info');
      setShowApiMessageModal(true);

    } catch (error) {
      console.error(error);
      setApiMessage(error.response?.data?.message || 'Failed to fetch service requests.');
      setApiMessageType('error');
      setShowApiMessageModal(true);
    }
  };

  const addRequestedService = (serviceId) => {
    setRequestedServices([...requestedServices, serviceId]);
  }


  if (!results.data.data.data.length) {
    return <div className="text-center">{t('SearchResults.NoResults')}</div>;
  }

  const { current_page, last_page, links, total } = results.data.data;
  
  const paginationItems = links.map((link, index) => {
    if (link.url) {
      const page = link.label === 'Next &raquo;' ? current_page + 1 : link.label === '&laquo; Previous' ? current_page - 1 : link.label;
      return (
        <Pagination.Item key={index} active={link.active} onClick={handlePageClick(page, latitude, longitude)}>
          {link.label.replace('&laquo; ', '').replace(' &raquo;', '')}
        </Pagination.Item>
      );
    }
    return null;
  });

  return (
    <>
      <div className="d-flex justify-content-between">
        <Pagination>{paginationItems}</Pagination>
        <b>{t('SearchResults.ServiceFound', { count: total })}</b>
      </div>
      
      <ListGroup>
        {results.data.data.data.map(service => (
          (!service.user.system_preferences || 
            service.user.system_preferences.enable_service_visibility === undefined || 
            service.user.system_preferences.enable_service_visibility === 1) && 
            <SearchResultItem
              key={service.id}
              service={service}
              handleRequestService={() => handleRequestService(service)}
              handleRequestQuote={() => handleRequestQuote(service)}
              handleSendMessage={() => handleOpenSendMessageModal(service)}
              isServiceRequested={requestedServices.includes(service.id)}
            />
        ))}
      </ListGroup>
      
      <Pagination className="justify-content-center mt-2">{paginationItems}</Pagination>

      <RequiredLogin 
        showRequiredLoginModal={showRequiredLoginModal}
        setShowRequiredLoginModal={setShowRequiredLoginModal}
        messageComplement={messageComplement}
        validateSession={validateSession}
        handleOpenLoginModal={handleOpenLoginModal}
        handleOpenRegisterModal={handleOpenRegisterModal}
        setPendingAction={setPendingAction}
      />

      <LoginModal 
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        onCancel={() => setShowLoginModal(false)}
      />

      <RegisterClientModal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
        onCancel={() => setShowRegisterModal(false)}
      />

      <ServiceRequestModal
        service={selectedService}
        show={showServiceRequestModal}
        handleClose={handleCloseServiceRequestModal}
        addRequestedService={addRequestedService}
      />

      <SendMessageModal
        show={showSendMessageModal}
        onHide={() => setShowSendMessageModal(false)}
        onSubmit={handleSendMessage}
      />

      <ModalMessage
        show={showApiMessageModal}
        onHide={() => setShowApiMessageModal(false)}
        title={apiMessageType === 'error' ? 'Error' : 'Information'}
        message={apiMessage}
        type={apiMessageType}
      />

    </>
  );
};

export default SearchResults;