import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/App.css';

function Hero({ searchKeyword, setSearchKeyword, handleKeyDown, loading, handleSearch }) {
  
  const { t } = useTranslation();

  return (
    <div className="hero">
      <div className="container">
        <div className="hero-content text-center">
          <h1 className="display-4">{t('App.Title')}</h1>
          <p className="lead">{t('App.Slogan')}</p>
          <div className="search-bar p-3 mt-4 rounded">
            <div className="input-group">
              <input
                type="text"
                className="form-control search-input"
                placeholder={t('Hero.SearchPlaceholder')}
                onChange={e => setSearchKeyword(e.target.value)}
                onKeyDown={handleKeyDown}
                value={searchKeyword}
              />
              <button className="btn btn-outline-secondary" onClick={handleSearch}>
                {loading ? t('Hero.Searching') : t('Hero.SearchButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
