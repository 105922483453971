import React, { useState, useEffect, useRef } from 'react';

import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import StarRating from './StarRating';

const RatingModal = ({ show, onHide, onRate }) => {

  const { t } = useTranslation();

  const [isValidRating, setIsValidRating] = useState(false);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const reviewRef = useRef(null);

  useEffect(() => {
    if (show) {
      // Resetea el estado solo cuando el modal se muestra
      setRating(0);
      setReview('');
      setIsValidRating(false);
    }
  }, [show]);

  const handleRate = () => {
    onRate({ rating, review });
    onHide();
  };

  const handleChangeRating = (newRating) => {
    setRating(newRating);
    setIsValidRating(newRating > 0);  // Establece si la calificación es válida
    // Enfoca el área de texto después de cambiar la calificación
    if (reviewRef.current) {
      reviewRef.current.focus();
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('RatingModal.RateService')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          <StarRating
            value={rating}
            totalStars={5}
            onChange={handleChangeRating}
            size={50}
            editable={true}
          />
        </div>
        {!isValidRating && <small><Alert variant="warning" className="mt-2">{t('RatingModal.SelectRatingAndPressSend')}</Alert></small>}
        <Form.Group controlId="formBasicTextarea">
          <Form.Label>{t('RatingModal.WriteAReview')}</Form.Label>
          <Form.Control
            as="textarea"
            ref={reviewRef}
            rows={3}
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder={t('RatingModal.TypeYourCommentHere')}
            maxLength={1024}  // MySQL TEXT limit
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('RatingModal.Cancel')}
        </Button>
        <Button variant="primary" onClick={handleRate} disabled={!isValidRating}>
          {t('RatingModal.Submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RatingModal;

