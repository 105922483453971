import React, { useState } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SendMessageModal = ({ show, onHide, onSubmit }) => {

    const { t } = useTranslation();
    const [message, setMessage] = useState('');

    const handleSubmit = () => {
        if (message.trim()) {
            onSubmit(message);
            setMessage('');
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('SendMessageModal.SendMessage')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>{t('SendMessageModal.Message')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder={t('SendMessageModal.TypeYourMessageHere')}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('SendMessageModal.Cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t('SendMessageModal.Send')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SendMessageModal;