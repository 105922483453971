import React, { useState, useEffect } from 'react';

import { verifiedUniqueUserField, sendEmailCodeToUpdate, validateEmailVerificationCode } from '../services/TaskiMapApiService';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateEmailModal = ({ show, onHide, onSubmit }) => {

  const { t } = useTranslation();

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(90);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');


  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            return 90;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);


  const handleRequestCode = async () => {
    setIsTimerActive(true);
    try {
      // First validate if the email is unique
      const validateResponse = await verifiedUniqueUserField('email', email);
      if (validateResponse.data.status === 'error') {
        setErrorMessage(validateResponse.data.message);
        setIsTimerActive(false);
        return;
      }

      // If unique, request verification code
      const response = await sendEmailCodeToUpdate(email);
      if (response.data.status) {
        setIsTimerActive(true);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message || t('UpdateEmailModal.FailedToSendVerificationCode'));
        setIsTimerActive(false);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || t('UpdateEmailModal.NetworkError'));
      setIsTimerActive(false);
    }
  };


  const handleVerifyCode = async () => {
    try {
      const response = await validateEmailVerificationCode(code);
      if (response.data.status) {
        onSubmit('email', email); // This should ideally update the email in your state or perform a re-fetch/query
        onHide();
        resetModal();
      } else {
        setErrorMessage(response.data.message || t('UpdateEmailModal.InvalidVerificationCode'));
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || t('UpdateEmailModal.NetworkError'));
    }
  };


  const resetModal = () => {
    setEmail('');
    setCode('');
    setIsTimerActive(false);
    setTimer(90);
    setErrorMessage('');
  };


  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('UpdateEmailModal.Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form>
            <Form.Group>
              <Form.Control
                placeholder={t('UpdateEmailModal.EnterNewEmail')}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={isTimerActive}
                disabled={isTimerActive}
                className="mb-1"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                placeholder={t('UpdateEmailModal.VerificationCodePlaceholder')}
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled={!isTimerActive}
              />
            </Form.Group>
          </Form>
        </>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="primary" onClick={handleRequestCode} disabled={isTimerActive}>
            {isTimerActive ? `${timer}s` : t('UpdateEmailModal.RequestCodeButton')}
          </Button>
          <Button variant="warning" onClick={handleVerifyCode} disabled={!code}>
            {t('UpdateEmailModal.VerifyAndUpdateEmailButton')}
          </Button>
        <Button variant="secondary" onClick={onHide}>
          {t('UpdateEmailModal.CancelButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateEmailModal;

