import React from 'react';

import { Card, Button, Carousel, Row, Col } from 'react-bootstrap';
import RatingDisplay from './RatingDisplay';
import { MdDelete } from "react-icons/md";

function ServiceCard({ service, onEdit, onDelete }) {

  const hasImages = service.files && service.files.length > 0;
  const ratingValue = parseFloat(service.average_rating) || 0;
  const ratingCount = service.ratings_count || 0;

  return (
    <Card className="mb-3 shadow-sm">
      <Row noGutters>
        <Col md={4}>
          {hasImages ? (
            <Carousel interval={null}>
              {service.files.map((file, idx) => (
                <Carousel.Item key={idx}>
                  <div style={{ height: '200px', overflow: 'hidden' }}>
                    <img
                      className="d-block w-100"
                      src={file.url}
                      alt={`Slide ${idx + 1}`}
                      style={{ height: '200px', objectFit: 'cover' }}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div className="text-center py-5" style={{ height: '200px' }}>Sin imágenes</div>
          )}
        </Col>
        <Col md={8}>
          <Card.Body>
            <Row className="w-100">
              <Col md={8}>
                <Card.Title>{service.name}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
                <Card.Text><strong>Precio:</strong> ${service.unit_price}</Card.Text>
                <Card.Text><strong>Categoría:</strong> {service.category?.display_name}</Card.Text>
              </Col>
              <Col md={4} className="d-flex flex-column align-items-end">
                <RatingDisplay ratingValue={ratingValue} ratingCount={ratingCount} />
              </Col>
            </Row>
            <Row className="w-100">
              <Col className="w-100 text-end">
                
                <Button variant="primary" onClick={() => onEdit(service)} className="me-1">
                  Editar
                </Button>
                <Button variant="danger" onClick={() => onDelete(service.id)}>
                  <MdDelete />
                </Button>
                
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

export default ServiceCard;

