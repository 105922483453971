import React, { useRef } from 'react';

import { InputGroup, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CustomSelect = ({ label, labelHtml, name, options, editable, value, onChange }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  
  return (
    <>
      <InputGroup className="mt-1">
        <Form.Control
          as="select"
          name={name}
          ref={inputRef}
          required
          readOnly={!editable}
          value={value !== undefined ? value : ''}
          style={{ border: 'none', borderRadius: '5px' }}
          className="custom-select"
          onChange={onChange}
        >
          <option key="noValue_1" value="">{t('Generic.Select')}</option>

          {options.map((option, index) => (
            <option key={option.id || index} value={option.id}>
              {option.label}
            </option>
          ))}
        </Form.Control>

      </InputGroup>

      { label && 
        <Form.Text style={{ marginLeft: '11px', fontSize: '0.8rem', color: 'lightgray', top: '-3px', position: 'relative' }}>
          {labelHtml ? <span dangerouslySetInnerHTML={{ __html: labelHtml }} /> : label}
        </Form.Text>
      }
    </>
  );
};

export default CustomSelect;