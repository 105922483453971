import React, { useState } from 'react';

import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

function RegisterClientForm({ formData, handleChange, handleSubmit, loading, error, onCancel }) {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.split('-')[0];
  const [acceptTerms, setAcceptTerms] = useState(false);

  return (
    <Form onSubmit={handleSubmit} className="register-form shadow-lg p-4 rounded">
      <h1 className="h3 mb-3 text-center register-title">{t('RegisterUser.Title')}</h1>

      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Group className="mb-3">
        <Form.Label className="form-label">{t('RegisterUser.Email')}</Form.Label> 
        <Form.Control 
          type="email" 
          name="email" 
          required 
          value={formData.email} 
          onChange={handleChange} 
          placeholder={t('RegisterUser.EmailPlaceholder')} 
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('RegisterUser.Password')}</Form.Label>
        <Form.Control
          type="password"
          name="password"
          required
          value={formData.password}
          onChange={handleChange}
          placeholder={t('RegisterUser.PasswordPlaceholder')}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('RegisterUser.ConfirmPassword')}</Form.Label>
        <Form.Control
          type="password"
          name="password_confirmation"
          required
          value={formData.password_confirmation}
          onChange={handleChange}
          placeholder={t('RegisterUser.ConfirmPasswordPlaceholder')}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check 
          type="checkbox"
          id="acceptTerms"
          label={
            <span>
              <Trans i18nKey="RegisterServiceProvider.AcceptTermsText">
                Acepto los <a href={`/${currentLanguage}/terms-and-conditions`}>términos y condiciones</a> y la <a href={`/${currentLanguage}/privacy-policy`}>política de privacidad</a>.
              </Trans>
            </span>
          }
          checked={acceptTerms}
          onChange={(e) => setAcceptTerms(e.target.checked)}
          required
        />
      </Form.Group>

      <div className="d-flex justify-content-between mt-4">
        <Button variant="link" onClick={onCancel}>
          {t('RegisterUser.Cancel')}
        </Button>

        <Button variant="primary" type="submit" disabled={loading || !acceptTerms} className="btn-lg register-button">
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('RegisterUser.Register')}
        </Button>
      </div>
    </Form>
  );
}

export default RegisterClientForm;

