import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalMessage({ show, onHide, title, message, type }) {
    const getModalHeaderClass = () => {
        switch (type) {
            case 'error':
                return 'bg-danger text-white';
            case 'warning':
                return 'bg-warning text-dark';
            case 'info':
                return 'bg-info text-white';
            default:
                return 'bg-primary text-white';
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className={getModalHeaderClass()} closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalMessage;