import React, { useState, useEffect } from 'react';

import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import { useUser } from '../contexts/UserContext';
import { useTranslation, Trans } from 'react-i18next';

const LoginForm = ({ onClose, callBackAction }) => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language.split('-')[0];
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { login } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      await login({ email, password });
      setLoading(false);
      if (callBackAction && typeof callBackAction === 'function') {
        callBackAction();
      }
      onClose();
    } catch (err) {
      console.log({err});
      setError(err.response?.data?.message || t('Errors.loginFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleLogin}>
        <Form.Group className="mb-3">
          <Form.Label>{t('LoginButton.Email')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('LoginButton.EmailPlaceholder')}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('LoginButton.Password')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('LoginButton.PasswordPlaceholder')}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <span>
            <Trans i18nKey="LoginButton.ForgotMyPassword">
              Ir a <a href={`/${currentLanguage}/password-recovery`}>recuperar clave</a>.
            </Trans>
          </span>
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onClose}>
            {t('LoginButton.Cancel')}
          </Button>
          <Button type="submit" variant="primary" disabled={loading} className="ms-2">
            {loading ? <Spinner as="span" animation="border" size="sm" /> : t('LoginButton.Login')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default LoginForm;

