import React from 'react';

import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';

const VerificationStatusBadge = ({ status }) => {
  
  const { t } = useTranslation();

  const statusColor = {
    started: 'primary',
    on_verification: 'warning',
    rejected: 'danger',
    approved: 'success'
  };

  const statusLabel = {
    started: t('VerificationStatus.Started'),
    on_verification: t('VerificationStatus.OnVerification'),
    rejected: t('VerificationStatus.Rejected'),
    approved: t('VerificationStatus.Approved')
  };

  return (
    <h5>
      {t('VerificationStatus.CurrentStatus')}:
      <Badge bg={statusColor[status]} className="ms-2 mt-2">{statusLabel[status]}</Badge>
    </h5>
  );
};

export default VerificationStatusBadge;