import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';

const LoginButton = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const callBackAction = () => {
    window.location.reload()
  }

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} className="ms-2">
        {t('LoginButton.Login')}
      </Button>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('LoginButton.Title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm onClose={handleClose} callBackAction={callBackAction} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginButton;