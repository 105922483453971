import React, { useState, useEffect } from 'react';

import { fetchServices, deleteService } from '../services/TaskiMapApiService';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import LoadingScreen from '../components/LoadingScreen';
import ServiceDetail from '../components/ServiceDetail';
import ServiceCard from '../components/ServiceCard';
import Sidebar from '../components/Sidebar';
import { FiPlus } from "react-icons/fi";
import useAuth from '../hooks/useAuth';
import '../styles/App.css';

function Services() {

  const [modalTitle, setModalTitle] = useState('Agregar servicio');
  const [selectedService, setSelectedService] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [services, setServices] = useState([]);
  const { isLoading } = useAuth();


  const getServices = async () => {
    try {
      const { data } = await fetchServices();
      setServices(data.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };


  const handleShowModal = (title, service = null) => {
    setModalTitle(title);
    setSelectedService(service ? { ...service } : null);
    setShowModal(true);
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedService(null);
    getServices();
  };


  useEffect(() => {
    getServices();
  }, []);


  const handleServiceSaved = (savedService) => {
    if (selectedService) {
      // Es una edición, actualiza el servicio existente en el array
      const updatedServices = services.map(service =>
        service.id === savedService.id ? savedService : service
      );
      setServices(updatedServices);
    } else {
      // Es un nuevo servicio, lo añade al array
      if (savedService && typeof savedService === 'object' && Object.keys(savedService).length > 0) {
        setServices([...services, savedService]);
      }
    }
    handleCloseModal();
  };


  const handleDeleteService = async (serviceId) => {
    if (window.confirm('¿Estás seguro de querer eliminar este servicio?')) {
      try {
        await deleteService(serviceId);
        const updatedServices = services.filter(service => service.id !== serviceId);
        setServices(updatedServices);
        alert('Servicio eliminado correctamente');
      } catch (error) {
        console.error('Error al eliminar el servicio:', error);
        alert('Error al eliminar el servicio');
      }
    }
  };


  return (
    <div className="d-flex">
      <Sidebar />
      
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">

        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {services.length > 0 ? (
              <>
                <div className="w-100 p-3">
                  {services.map(service => (
                    <ServiceCard
                      key={service.id}
                      service={service}
                      onEdit={(service) => handleShowModal('Editar servicio', service)}
                      onDelete={handleDeleteService}
                    />
                  ))}
                </div>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Agregar servicio</Tooltip>}
                >
                  <button
                    className="btn rounded-circle p-3 position-absolute"
                    style={{
                      width: '70px',
                      height: '70px',
                      border: '2px solid gray',
                      color: 'gray',
                      background: 'transparent',
                      transition: 'all 0.3s ease',
                      right: '20px',
                      bottom: '20px',
                      transform: 'translate(0, 0)'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.background = 'gray';
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.background = 'transparent';
                      e.currentTarget.style.color = 'gray';
                    }}
                    onClick={() => handleShowModal('Agregar servicio')}
                  >
                    <FiPlus style={{ fontSize: '30px' }} />
                  </button>
                </OverlayTrigger>
              </>

            ) : (
              <div className="text-center w-100">
                <h2>Crea tu primer servicio aquí</h2>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Agregar servicio</Tooltip>}
                >
                  <button 
                    className="btn rounded-circle p-3 align-items-center justify-content-center mt-3"
                    style={{
                      width: '70px',
                      height: '70px',
                      border: '2px solid gray',
                      color: 'gray',
                      background: 'transparent',
                      transition: 'all 0.3s ease'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.background = 'gray';
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.background = 'transparent';
                      e.currentTarget.style.color = 'gray';
                    }}
                    onClick={() => handleShowModal('Agregar servicio')}
                  >
                    <FiPlus style={{ fontSize: '30px' }} />
                  </button>
                </OverlayTrigger>
              </div>

            )}
          </>
        )}

        <ServiceDetail
          title={modalTitle}
          data={selectedService}
          show={showModal}
          onHide={handleCloseModal}
          onSave={handleServiceSaved}
        />

      </div>
    </div>
  );
}

export default Services;

