import React, { useState, useEffect } from 'react';

import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { verifiedUniqueUserField, sendSMSCodeToUpdate, validateSMSVerificationCode, fetchCountries } from '../services/TaskiMapApiService';
import { useTranslation } from 'react-i18next';
import { normalizeText } from '../utils/textUtils';

const UpdatePhoneModal = ({ show, onHide, onSubmit }) => {
  const { t } = useTranslation();
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [timer, setTimer] = useState(90);
  const [code, setCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countries, setCountries] = useState([]);
  const storedLocation = JSON.parse(localStorage.getItem('geoData'));
  

  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            return 90;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  // Cargar países y preseleccionar basado en geoData
  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await fetchCountries();
        const countryList = response.data.data;
        setCountries(countryList);

        // Extraer el país del campo 'location' en storedLocation
        const geoCountry = storedLocation?.location.split(',').pop().trim().toLowerCase();
        const normalizedGeoCountry = normalizeText(geoCountry); // Normalizamos el nombre del país

        // Función para verificar la similitud entre cadenas
        const stringSimilarity = (str1, str2) => {
          const normalizedStr1 = normalizeText(str1);
          const normalizedStr2 = normalizeText(str2);
          
          let longer = normalizedStr1.length > normalizedStr2.length ? normalizedStr1 : normalizedStr2;
          let shorter = normalizedStr1.length > normalizedStr2.length ? normalizedStr2 : normalizedStr1;

          let commonLength = shorter.split('').filter(char => longer.includes(char)).length;

          return commonLength / longer.length;
        };

        // Función para verificar la coincidencia del país en varios campos
        const findCountryMatch = (country) => {
          const normalizedName = normalizeText(country.name);
          const normalizedDisplayName = normalizeText(country.display_name);
          const normalizedI18nLabel = normalizeText(country.i18n_label);

          // Calculamos la similitud con cada campo
          const nameSimilarity = stringSimilarity(normalizedGeoCountry, normalizedName);
          const displayNameSimilarity = stringSimilarity(normalizedGeoCountry, normalizedDisplayName);
          const i18nLabelSimilarity = stringSimilarity(normalizedGeoCountry, normalizedI18nLabel);

          // Consideramos una coincidencia si la similitud es mayor al 60%
          const threshold = 0.6;
          return (
            nameSimilarity > threshold ||
            displayNameSimilarity > threshold ||
            i18nLabelSimilarity > threshold
          );
        };

        // Buscar coincidencia de país usando el enfoque mejorado
        const foundCountry = countryList.find(findCountryMatch);

        // Solo actualizamos si el código de país cambia
        if (foundCountry && foundCountry.phone_code !== countryCode) {
          setCountryCode(foundCountry.phone_code);
        }
      } catch (err) {
        console.error('Error fetching countries:', err);
      }
    };

    if (storedLocation) {
      getCountries();
    }
  }, []);


  const handleRequestCode = async () => {
    setIsTimerActive(true);
    try {
      const validateResponse = await verifiedUniqueUserField('phone', `${countryCode}${phone}`);
      if (validateResponse.data.status === 'error') {
        setErrorMessage(validateResponse.data.message);
        setIsTimerActive(false);
        return;
      }

      const response = await sendSMSCodeToUpdate(`${countryCode}${phone}`);
      if (response.data.status) {
        setIsTimerActive(true);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message || t('UpdatePhoneModal.FailedToSendVerificationCode'));
        setIsTimerActive(false);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || t('UpdatePhoneModal.NetworkError'));
      setIsTimerActive(false);
    }
  };

  const handleVerifyCode = async () => {
    try {
      const response = await validateSMSVerificationCode(code);
      if (response.data.status) {
        onSubmit('phone', `${countryCode}${phone}`);
        onHide();
        resetModal();
      } else {
        setErrorMessage(response.data.message || t('UpdatePhoneModal.InvalidVerificationCode'));
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || t('UpdatePhoneModal.NetworkError'));
    }
  };

  const resetModal = () => {
    setPhone('');
    setCode('');
    setIsTimerActive(false);
    setTimer(90);
    setErrorMessage('');
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('UpdatePhoneModal.Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form>
          <Form.Group className="d-flex mb-3">
            <Form.Select
              aria-label={t('UpdatePhoneModal.SelectCountryCode')}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="me-2"
              required
              style={{ width: '35%' }}
            >
              <option value="">{t('UpdatePhoneModal.SelectCountryCode')}</option>
              {countries.map((country) => (
                <option key={country.id} value={country.phone_code}>
                  {t(country.i18n_label)} ({country.phone_code})
                </option>
              ))}
            </Form.Select>

            <Form.Control
              placeholder={t('UpdatePhoneModal.EnterNewPhone')}
              type="text"
              value={phone.replace(countryCode, '')} // Solo muestra el número sin el código de país
              onChange={(e) => setPhone(e.target.value)}
              readOnly={isTimerActive}
              disabled={isTimerActive}
              className="mb-1"
              style={{ width: '65%' }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Control
              placeholder={t('UpdatePhoneModal.VerificationCodePlaceholder')}
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              disabled={!isTimerActive}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleRequestCode} disabled={isTimerActive}>
          {isTimerActive ? `${timer}s` : t('UpdatePhoneModal.RequestCodeButton')}
        </Button>
        <Button variant="warning" onClick={handleVerifyCode} disabled={!code}>
          {t('UpdatePhoneModal.VerifyAndUpdatePhoneButton')}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          {t('UpdatePhoneModal.CancelButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePhoneModal;