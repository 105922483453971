import React, { useState } from 'react';

import RegisterClientForm from './RegisterClientForm';
import { useUser } from '../contexts/UserContext';
import { Modal } from 'react-bootstrap';

const RegisterClientModal = ({ show, onHide, onSuccess, onCancel }) => {

    const { registerClient } = useUser();
    
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        password_confirmation: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await registerClient(formData);
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body>
                <RegisterClientForm
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    onCancel={onCancel}
                />
            </Modal.Body>
        </Modal>
    );
};

export default RegisterClientModal;