import React, { useState } from 'react';

import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/App.css';

function RegisterButton() {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language.split('-')[0];
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const renderTooltip = (message) => (
    (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    )
  );

  const handleUserRegister = () => {
    handleClose();
    navigate(`/${currentLanguage}/register-user`);
  };

  const handleServiceProviderRegister = () => {
    handleClose();
    navigate(`/${currentLanguage}/register-service-provider`);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {t('RegisterButton.SignUp')}
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('RegisterButton.SelectAccountTypeTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center custom-modal-body">
          <p>{t('RegisterButton.SelectAccountType')}:</p>
          <div className="d-flex justify-content-around custom-button-container">
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip(t('RegisterButton.UserAccountDescription'))}
            >
              <Button variant="outline-success custom-button" onClick={handleUserRegister}>
                {t('RegisterButton.User')}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={renderTooltip(t('RegisterButton.ServiceProviderAccountDescription'))}
            >
              <Button variant="outline-info custom-button" onClick={handleServiceProviderRegister}>
                {t('RegisterButton.ServiceProvider')}
              </Button>
            </OverlayTrigger>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegisterButton;

