import React, { useState, useEffect } from 'react';

import { Container, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; 
import { FaCookieBite } from 'react-icons/fa';
import '../styles/App.css';

const CookieConsent = () => {

  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShow(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({
      necessary: true,
      analytics: true,
      marketing: true
    }));
    setCookiesAccepted(true);
    setShow(false);
  };

  const handleAcceptSelected = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const cookieConsent = {
      necessary: true,
      analytics: formData.get('analytics') === 'on',
      marketing: formData.get('marketing') === 'on'
    };
    localStorage.setItem('cookieConsent', JSON.stringify(cookieConsent));
    setCookiesAccepted(true);
    setShow(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({
      necessary: true,
      analytics: false,
      marketing: false
    }));
    setCookiesAccepted(true);
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className="cookie-consent-bar">
      <Container fluid className="d-flex flex-column align-items-center text-center">
        <div className="d-flex align-items-center mb-3">
          <FaCookieBite className="cookie-icon me-2" />
          <p className="cookie-text mb-0">
            {t('CookieConsent.WeUseCookiesConsentText')}
          </p>
        </div>
        <Form className="d-flex flex-column align-items-center" onSubmit={handleAcceptSelected}>
          <div className="d-flex justify-content-center mb-4 w-100">
            <Form.Check 
              type="checkbox"
              id="necessary"
              label={t('CookieConsent.NecessaryCookies')}
              checked
              disabled
              className="me-4"
            />
            <Form.Check 
              type="checkbox"
              id="analytics"
              name="analytics"
              label={t('CookieConsent.AnalyticsCookies')}
              className="me-4"
            />
            <Form.Check 
              type="checkbox"
              id="marketing"
              name="marketing"
              label={t('CookieConsent.MarketingCookies')}
            />
          </div>
          <div className="button-group d-flex justify-content-center w-100">
            <Button variant="success" onClick={handleAcceptAll} className="me-2">
              {t('CookieConsent.AccepAll')}
            </Button>
            <Button variant="primary" type="submit" className="me-2">
              {t('CookieConsent.AcceptSelected')}
            </Button>
            {/*<Button variant="secondary" onClick={handleDecline}>
              {t('CookieConsent.DeclineNonNecessary')}
            </Button>*/}
          </div>
        </Form>
        <div className="privacy-link mt-3">
          <a href="/privacy-policy" className="me-2" target="_blank" rel="noopener noreferrer">{t('CookieConsent.PrivacyPolicy')}</a>
          <a href="/temrs" target="_blank" rel="noopener noreferrer">{t('CookieConsent.TermsAndConditions')}</a>
        </div>
      </Container>
    </div>
  );
};

export default CookieConsent;