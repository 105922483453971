import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Componente que muestra un modal para requerir inicio de sesión o registro.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.showRequiredLoginModal - Controla la visibilidad del modal.
 * @param {Function} props.setShowRequiredLoginModal - Función para cambiar la visibilidad del modal.
 * @param {string} props.messageComplement - Mensaje complementario mostrado en el modal.
 */
const RequiredLogin = ({ 
  showRequiredLoginModal, 
  setShowRequiredLoginModal, 
  messageComplement,
  handleOpenRegisterModal,
  handleOpenLoginModal,
  setPendingAction
}) => {
  
  const { t } = useTranslation();
  
  /**
   * Cierra el modal de requerimiento de inicio de sesión.
   */
  const handleCloseModal = () => {
    setShowRequiredLoginModal(false);
    setPendingAction(null);
  };

  return (
    <>
      <Modal show={showRequiredLoginModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <div className="text-center mb-4">
            {t('RequiredLogin.LoginRequiredBody', { complement: messageComplement })}
          </div>

          <div className="text-center mt-2">
            <Button variant="primary" onClick={handleOpenLoginModal}>
              {t('RequiredLogin.LoginButton')}
            </Button>
          </div>
          <div className="text-center mt-2">
            {t('RequiredLogin.or')}
          </div>
          <div className="text-center mt-2">
            <Button variant="btn btn-outline-primary" onClick={handleOpenRegisterModal}>
              {t('RequiredLogin.RegisterButton')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequiredLogin;

